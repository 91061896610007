import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ChatsSvg } from "../../assets/media/icons/chats.svg";
import { ReactComponent as ReportsSvg } from "../../assets/media/icons/poll.svg";
import { ReactComponent as AccountSvg } from "../../assets/media/icons/accounts.svg";
import { ReactComponent as PortalSvg } from "../../assets/media/icons/favourite.svg";

function NavBar(props) {
    return (
        <div className="navigation navbar navbar-light bg-primary">
            <ul
                className="nav nav-minimal flex-row flex-grow-1 justify-content-between"
                id="mainNavTab"
                role="tablist"
            >
                <li className="nav-item">
                    <Link
                        className={
                            "nav-link p-0 py-xl-3 " +
                            (props.activeMenu === "portal" ? "active" : "")
                        }
                        id="portal-tab"
                        to="/portal"
                        title="Portal"
                    >
                        <PortalSvg />
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={
                            "nav-link p-0 py-xl-3 " +
                            (props.activeMenu === "chat" ? "active" : "")
                        }
                        id="chats-tab"
                        to="/chat"
                        title="Chats"
                    >
                        <ChatsSvg />
                    </Link>
                </li>
                <li className="nav-item">
                    <Link
                        className={
                            "nav-link p-0 py-xl-3 " +
                            (props.activeMenu === "account" ? "active" : "")
                        }
                        id="profile-tab"
                        to="/account"
                        title="Account"
                    >
                        <AccountSvg />
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export default NavBar;
