import React, { Component } from "react";
import Footer from "./Footer";


class TermsOfService extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <div className={"main-layout vh-100 card-bg-1"}>
                <div className="d-flex flex-column h-100 hide-scrollbar">
                    <div className="container d-flex flex-column">
                        <div className="row no-gutters text-center align-items-center justify-content-center">
                            <div className="col-12">
                                <div className="row mb-3">
                                    <div className="col-12 mb-2">
                                        <img src="/images/logo2.png" alt="Only Sats" className="img-fluid m-2" />
                                        <h1 className="m-2" style={{ fontSize: "18px" }}>The boring but necessary stuff</h1>
                                    </div>
                                    <div className="col-md-8 offset-md-2">
                                        <p className="text-white text-left">TODO</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default TermsOfService;