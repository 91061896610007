// Used for updating the creator's avatar on the profile settings component
import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { FileUploadWithPreview } from 'file-upload-with-preview/dist/file-upload-with-preview.esm';
import 'file-upload-with-preview/dist/file-upload-with-preview.min.css';

class ChangeAvatar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            uploader: null
        };
    }

    componentDidMount() {
        this.setState({
            uploader: new FileUploadWithPreview('attachMedia', {
                accept: 'image/jpeg,image/png',
                maxFileCount: 1,
                multiple: false,
                presetFiles: [],
                text: {
                    browse: 'Choose',
                    chooseFile: 'Choose your avatar',
                    label: ''
                }
            })
        });
    }

    setModalShow = () => {
        this.props.hideChangeAvatar();
    };


    updateAvatar = async () => {
        if (this.state.uploader.cachedFileArray &&
            this.state.uploader.cachedFileArray.length > 0) {

            this.props.setAvatar({
                avatar: this.state.uploader.cachedFileArray[0]
            });
        } else {
            console.log('no files');
        }

        this.props.hideChangeAvatar();
    }

    render() {
        return (
            <Modal
                show={true}
                scrollable={true}
                onHide={() => this.setModalShow(false)}
            >

                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Avatar</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0 hide-scrollbar">

                    <div className="custom-file-container" data-upload-id="attachMedia"></div>

                    <div className="d-flex justify-content-center mt-2 mb-2">
                        <button
                            type="button"
                            className="btn btn-outline-danger mx-1"
                            onClick={() => this.setModalShow(false)}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary mx-1"
                            onClick={this.updateAvatar}
                        >
                            Update
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ChangeAvatar;