import React, { Component } from "react";

import "./ChatStart.css";

// Display when no Contact is selected
class ChatStart extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="start-chat" >
                <div className="start-chat-container" >
                    <img alt="" src="/images/icon-128.png" />
                    <p>Browse profiles or select an existing chat to get started</p>
                </div>
            </div>
        );
    }
}

export default ChatStart;