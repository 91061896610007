import React, { Component } from "react";

import "./SettingsStart.css";

class SettingsStart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };
    }

    async componentDidMount() {

        this.setState({
            loading: false,
            // TODO: More
        });
    }

    render() {
        return (
            <div id="start-settings" >
                <div className="start-settings-container" >
                    <img alt="" src="/images/icon-128.png" />
                    <p>Manage your account settings and profile here. Select an option on the left to get started</p>
                </div>
            </div>
        );
    }
}

export default SettingsStart;