import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { FileUploadWithPreview } from 'file-upload-with-preview/dist/file-upload-with-preview.esm';
import 'file-upload-with-preview/dist/file-upload-with-preview.min.css';

class ChatMedia extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploader: null
        };
    }

    componentDidMount() {
        this.setState({
            uploader: new FileUploadWithPreview('attachMedia', {
                accept: 'image/*,video/*',
                maxFileCount: 5,
                multiple: true,
                presetFiles: [],
                text: {
                    browse: 'Choose',
                    chooseFile: 'Choose up to 5',
                    label: '',
                },
            })
        });
    }

    setModalShow = () => {
        this.props.hideChatMedia();
    };

    attachMedia = () => {
        this.props.setMediaMessage(this.state.uploader.cachedFileArray);
        this.props.hideChatMedia();
    }

    render() {
        return (
            <Modal
                show={true}
                scrollable={true}
                onHide={() => this.setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Attach Media</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0 hide-scrollbar">
                    <div className="row">
                        <div className="col-10 offset-1">

                            <div className="custom-file-container" data-upload-id="attachMedia"></div>

                            <div className="d-flex justify-content-center mt-2 mb-2">
                                <button
                                    type="button"
                                    className="btn btn-outline-danger mx-1"
                                    onClick={() => this.setModalShow(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary mx-1"
                                    onClick={this.attachMedia}
                                >
                                    Attach
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ChatMedia;