import React, { Component } from "react";
import "./ChatCenterbar.css";

import ChatDetail from "./ChatDetail";
import ChatStart from "./ChatStart";
import ChatPaywall from "./ChatPaywall";

class ChatCenterbar extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="center-bar">
                {
                    !this.props.openChat &&
                    <ChatStart />
                }
                {
                    this.props.openChat &&
                    this.props.chatSessionId > 0 &&
                    <ChatDetail
                        onSend={this.props.onSend}
                        messages={this.props.messages}
                        formattingMessages={this.props.formattingMessages}
                        chatSessions={this.props.chatSessions}
                        messageInput={this.props.messageInput}
                        changeMessageInput={this.props.changeMessageInput}
                        chatSessionId={this.props.chatSessionId}
                        onEmojiClick={this.props.onEmojiClick}
                        openEmojis={this.props.openEmojis}
                        handleOpenEmojis={this.props.handleOpenEmojis}
                        clickActiveChatSession={this.props.clickActiveChatSession}
                        setOpenChat={this.props.setOpenChat}
                        setActiveChatSession={this.props.setActiveChatSession}
                        onlineUsers={this.props.onlineUsers}
                        attachedMedia={this.props.attachedMedia}
                        messagePrice={this.props.messagePrice}
                        setAttachedMedia={this.props.setAttachedMedia}
                        setMessagePrice={this.props.setMessagePrice}
                        sendingMessage={this.props.sendingMessage}
                        theirUsername={this.props.theirUsername}
                        showPaywall={this.props.showPaywall}
                        bolt11={this.props.bolt11}
                        bolt11ExpiresAt={this.props.bolt11ExpiresAt}
                        houseFeeSats={this.props.houseFeeSats}
                        token={this.props.token}
                        claims={this.props.claims}
                        creatorId={this.props.creatorId}
                        allowPayWithAlby={this.props.allowPayWithAlby}
                    />
                }
            </div>
        );
    }
}

export default ChatCenterbar;