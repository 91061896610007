import React, { Component } from "react";
import ChatContact from "./ChatContact";
import "./ChatContacts.css";

// Container for all contacts
class ChatContacts extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                {
                    !this.props.loadingChatSessions &&
                    this.props.chatSessions.length > 0 &&

                    <ul className="contacts-list">
                        {
                            this.props.chatSessions.map(chatSession => (
                                <ChatContact
                                    {...chatSession}
                                    key={chatSession.id}
                                    clickActiveChatSession={this.props.clickActiveChatSession}
                                    chatSessionId={this.props.chatSessionId}
                                />
                            ))
                        }
                    </ul>
                }
                {
                    !this.props.loadingChatSessions &&
                    this.props.chatSessions.length == 0 &&
                    <div className="no-contacts">
                        <p>
                            No existing chat sessions
                        </p>
                    </div>
                }
                {
                    this.props.loadingChatSessions &&
                    <div className="no-contacts">
                        <p>
                            Loading...
                        </p>
                    </div>
                }
            </>
        );
    }
}

export default ChatContacts;