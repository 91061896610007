import React, { Component } from "react";

import { Accordion, Card, Button } from "react-bootstrap";
import Footer from "./Footer";

class HowItWorks extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <div className={"main-layout vh-100 card-bg-1"}>
                <div className="d-flex flex-column h-100 hide-scrollbar">
                    <div className="container d-flex flex-column">
                        <div className="row no-gutters text-center align-items-center justify-content-center">
                            <div className="col-12">
                                <div className="row mb-3">
                                    <div className="col-12 mb-2">
                                        <img src="/images/logo2.png" alt="Only Sats" className="img-fluid m-2" />
                                        <h1 className="m-2" style={{ fontSize: "18px" }}>Learn about OnlySats</h1>
                                    </div>
                                    <div className="col-md-10 offset-md-1">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe
                                                src="https://www.youtube.com/embed/JLNwZtf2ja4"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen></iframe>
                                        </div>
                                        <div className="mt-2">
                                            <h2 className="m-2" style={{ fontSize: "18px" }}>Step-by-step directions</h2>
                                            <Accordion defaultActiveKey="0" className="text-left text-white small">
                                                <Card>
                                                    <Card.Header>
                                                        <Accordion.Toggle as={Card.Header} eventKey="0">
                                                            For Creators
                                                        </Accordion.Toggle>
                                                    </Card.Header>

                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body>
                                                            <p>
                                                                We use the Bitcoin Lightning Network to provide global, permissionless, low-fee and instant payments. We make it as simple
                                                                as possible by leveraging Bitcoin payment processors such as <a href="https://opennode.com">OpenNode</a>.
                                                            </p>
                                                            <p>
                                                                There's a small learning curve to get started but it'll become second nature, we promise! To get started here's what you
                                                                need to do:
                                                            </p>
                                                            <ol className="mt-1">
                                                                <li>Signup for an account at <a href="https://app.opennode.com/signup" target="_blank">OpenNode</a></li>
                                                                <li>Watch <a href="#">this tutorial</a> to generate your API key</li>
                                                                <li>Signup for a Creator account <a href="https://onlysats.app/identity/account/register?returnUrl=%2Faccount">with us</a> and provide your OpenNode API key from step 2</li>
                                                                <li>Watch <a href="#">this tutorial</a> as Angela walks you through the product</li>
                                                            </ol>
                                                            <p className="mt-1">
                                                                Create. Get paid. Have fun.
                                                            </p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                                <Card>
                                                    <Card.Header>
                                                        <Accordion.Toggle as={Card.Header} eventKey="1">
                                                            For Fans
                                                        </Accordion.Toggle>
                                                    </Card.Header>

                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body>
                                                            <p>
                                                                Simply <a href="/identity/account/register?returnUrl=%2Faccount">sign up</a> and have a Bitcoin Lightning wallet ready.
                                                            </p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default HowItWorks;