import React, { Component } from "react";
import FindCreators from "../../Discover/FindCreators";
import Header from "../../NavBar/Header";
import NavBar from "../../NavBar/NavBar";
import TipCreator from "../../Modals/TipCreator/TipCreator";
import authService from '../../api-authorization/AuthorizeService';
import { Dropdown, DropdownButton } from "react-bootstrap";
import { ReactComponent as ChatSvg } from "../../../assets/media/heroicons/outline/chat.svg";
import { ReactComponent as CartSvg } from "../../../assets/media/heroicons/outline/shopping-cart.svg";
import { ReactComponent as StarSvg } from "../../../assets/media/heroicons/outline/star.svg"
import { BitcoinIcon } from '@bitcoin-design/bitcoin-icons-react/filled';


/* Homepage that contains all updates: Feeds, recommendations, etc. */
class Portal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            /* Get from Claims */
            myUserAccountId: 0,
            myUsername: '',
            token: '',
            isCreator: false,
            profileId: 0, /* either creatorId or patronId */
            creatorRevenue: {},
            patronSubscriptions: [],
            dateType: 2,
            loading: true,
            showTipCreator: false,
            selectedCreatorId: 0,
            selectedCreatorUsername: ''
        };
    }

    async componentDidMount() {
        const authenticated = await authService.isAuthenticated();

        if (!authenticated) {
            const baseUri = window.location.origin;
            window.location.href = `${baseUri}/authentication/login?returnUrl=${encodeURI(`${baseUri}/portal`)}`;
        }

        const token = await authService.getAccessToken();
        this.setState({
            token: token,
            isAuthenticated: authenticated
        });

        const response = await fetch('api/claims', {
            headers: { 'Authorization': `Bearer ${token}` }
        });

        if (response.status != 200) {
            const baseUri = window.location.origin;
            window.location.href = `${baseUri}/authentication/login?returnUrl=${encodeURI(`${baseUri}/portal`)}`;
        }

        const claims = await response.json();

        //console.log(claims);

        const isCreator = claims.UserRole[0].toLowerCase() === "creator";
        let profileId = 0;
        if (isCreator) {
            profileId = parseInt(claims.CreatorId[0]);
            await this.loadCreatorRevenue(2);
        } else {
            profileId = parseInt(claims.PatronId[0]);
            await this.loadPatronSubscriptions();
        }

        this.setState({
            myUserAccountId: parseInt(claims.UserAccountId[0]),
            myUsername: claims.Username[0],
            isCreator: isCreator,
            profileId: profileId,
            loading: false
        });
    }

    loadCreatorRevenue = async (dateType) => {
        const revenueResponse = await fetch(`api/profile/my_revenue?dateType=${dateType}`, {
            headers: { 'Authorization': `Bearer ${this.state.token}` }
        });

        var revenue = await revenueResponse.json();

        this.setState({
            creatorRevenue: revenue,
        });
    }

    loadPatronSubscriptions = async () => {
        const subscriptionsResponse = await fetch(`api/profile/patron_subscriptions`, {
            headers: { 'Authorization': `Bearer ${this.state.token}` }
        });

        var subscriptions = await subscriptionsResponse.json();

        this.setState({
            patronSubscriptions: subscriptions,
        });
    }

    setDateType = async (val) => {

        this.setState({
            loading: true,
            dateType: val
        });

        await this.loadCreatorRevenue(val);

        this.setState({
            loading: false
        });
    }

    toggleTipCreator = (id, username) => {

        this.setState({
            selectedCreatorId: id,
            selectedCreatorUsername: username,
            showTipCreator: !this.state.showTipCreator,
        });
    };

    numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render() {
        return (
            <>
                <div className={"main-layout vh-100"}>
                    <div className="hide-scrollbar h-100" id="profile-content" style={{ backgroundColor: '#495057' }}>
                        <div className="d-flex flex-column h-100">
                            <div className="hide-scrollbar">
                                {!this.state.loading &&
                                    <Header
                                        isAuthenticated={this.state.isAuthenticated}
                                        token={this.state.token}
                                    />
                                }
                                <div className="container-xl mt-1">
                                    {
                                        this.state.loading &&
                                        <p className="text-white">Loading...</p>
                                    }
                                    {
                                        !this.state.loading &&

                                        <div className="row">
                                            <div className="col">
                                                <div className="card text-white">
                                                    {
                                                        this.state.isCreator &&
                                                        <>
                                                            <div className="m-3">
                                                                <div
                                                                    className="d-flex justify-content-start"
                                                                >
                                                                    <h3 className="m-1">Revenue</h3>
                                                                    <DropdownButton
                                                                        className="border-0 f-16 m-1 p-0 cut-texts"
                                                                        variant="outline-default"
                                                                        onSelect={this.setDateType}
                                                                        title={"Change Date"}
                                                                    >
                                                                        <Dropdown.Item
                                                                            eventKey={1}
                                                                        >
                                                                            Today
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item
                                                                            eventKey={2}
                                                                        >
                                                                            Month
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item
                                                                            eventKey={3}
                                                                        >
                                                                            YTD
                                                                        </Dropdown.Item>
                                                                    </DropdownButton>
                                                                </div>
                                                                <div
                                                                    className="mt-2 d-flex justify-content-center"
                                                                >
                                                                    <p>{this.state.creatorRevenue.startDate} - {this.state.creatorRevenue.endDate}</p>
                                                                </div>
                                                                <div className="row mt-2">
                                                                    <div className="col-md-3 col-sm-6">
                                                                        <strong>Subscriptions</strong><br />
                                                                        <div className="alert alert-primary" role="alert">{this.numberWithCommas(this.state.creatorRevenue.subscriptions)}</div>
                                                                    </div>
                                                                    <div className="col-md-3 col-sm-6">
                                                                        <strong>Messages</strong> <br />
                                                                        <div className="alert alert-primary" role="alert">{this.numberWithCommas(this.state.creatorRevenue.messages)}</div>
                                                                    </div>
                                                                    <div className="col-md-3 col-sm-6">
                                                                        <strong>Tips</strong><br />
                                                                        <div className="alert alert-primary" role="alert">{this.numberWithCommas(this.state.creatorRevenue.tips)}</div>
                                                                    </div>
                                                                    <div className="col-md-3 col-sm-6">
                                                                        <strong>Total</strong><br />
                                                                        <div className="alert alert-primary" role="alert">{this.numberWithCommas(this.state.creatorRevenue.total)}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        !this.state.isCreator &&
                                                        <>
                                                            <div className="card m-3 p-1">
                                                                <div className="mx-5 mt-2">
                                                                    <FindCreators
                                                                        token={this.state.token}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {
                                                                (!this.state.patronSubscriptions ||
                                                                    this.state.patronSubscriptions.length == 0) &&
                                                                <>
                                                                    <h4 className="m-1 text-center">No subscriptions found</h4>

                                                                </>
                                                            }
                                                            {
                                                                this.state.patronSubscriptions &&
                                                                this.state.patronSubscriptions.length > 0 &&
                                                                <>
                                                                    <h4 className="m-1 text-center">Your Subscriptions</h4>
                                                                    <div className="row">
                                                                        {
                                                                            this.state.patronSubscriptions.map((subscription) => (
                                                                                <div className="col-md-4" key={subscription.creatorId}>
                                                                                    <div className="card m-3 p-1">
                                                                                        <div className="d-flex justify-content-center">
                                                                                            <div className="avatar avatar-xxl mx-5 mt-2">
                                                                                                <img
                                                                                                    className="avatar-img"
                                                                                                    src={subscription.creatorAvatar}
                                                                                                    alt=""
                                                                                                ></img>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex justify-content-center">
                                                                                            @{subscription.creatorUsername}
                                                                                        </div>

                                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                                            <div className="text-center">
                                                                                                <a className="nav-link text-muted px-0" href={'/p/' + subscription.creatorUsername}>
                                                                                                    <div className="btn btn-primary btn-icon rounded-circle text-light mx-2">
                                                                                                        <StarSvg className="injectable hw-20" />
                                                                                                    </div>
                                                                                                    <span className="small">Profile</span>
                                                                                                </a>
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <a className="nav-link text-muted px-0" href={'/chat?creatorId=' + subscription.creatorId}>
                                                                                                    <div className="btn btn-primary btn-icon rounded-circle text-light mx-2">
                                                                                                        <ChatSvg className="injectable hw-24" />
                                                                                                    </div>
                                                                                                    <span className="small">Chat</span>
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                                            <div className="text-center">
                                                                                                <a className="nav-link text-muted px-0" href={'/Store?creatorId=' + subscription.creatorId}>
                                                                                                    <div className="btn btn-primary btn-icon rounded-circle text-light mx-2">
                                                                                                        <CartSvg className="injectable hw-20" />
                                                                                                    </div>
                                                                                                    <span className="small">Store</span>
                                                                                                </a>
                                                                                            </div>
                                                                                            <div className="text-center">
                                                                                                <a className="nav-link text-muted px-0" onClick={() => this.toggleTipCreator(subscription.creatorId, subscription.creatorUsername)}>
                                                                                                    <div className="btn btn-primary btn-icon rounded-circle text-light mx-2">
                                                                                                        <BitcoinIcon className="injectable" style={{ height: "2rem", width: "2rem" }} />
                                                                                                    </div>

                                                                                                    <span className="small">Send Tip</span>
                                                                                                </a>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                                            <em>Expires: {subscription.dateExpires}</em>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>

                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <NavBar activeMenu="portal" />
                    <div className="backdrop"></div>
                </div>

                {
                    this.state.showTipCreator &&
                    <TipCreator
                        hideTipCreator={() => this.toggleTipCreator(0, '')}
                        creatorUsername={this.state.selectedCreatorUsername}
                        token={this.state.token}
                        creatorId={this.state.selectedCreatorId}
                        suggestedTip={25000}
                    />
                }
            </>
        );
    }
}

export default Portal;