import React, { Component } from "react";


class Footer extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <div className="container mb-1 mt-2" style={{borderTop: "solid 1px #FFF"} }>
                <div className="row">
                    <div className="col-md-3 col-6 text-left">
                        <a className="text-info" href="/help">Help</a>
                    </div>
                    <div className="col-md-3 col-6 text-left">
                        <a className="text-info" href="/how-it-works">How it works</a>
                    </div>
                    <div className="col-md-3 col-6 text-left">
                        <a className="text-info" href="/about">About</a>
                    </div>
                    <div className="col-md-3 col-6 text-left">
                        <a className="text-info" href="/terms-of-service">Terms of Service</a>
                    </div>
                </div>
                <div className="col-12">
                    <p className="text-center m-2 text-white">
                        &copy; 2022 Copyright &nbsp;
                        <a className="text-info" href="https://onlysats.app">OnlySats</a>
                    </p>
                </div>
            </div>
        );
    }
}

export default Footer;