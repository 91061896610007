import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";

import Home from "../components/Home/Home";
import ChatMaster from "../components/NewChat/ChatMaster";
import Settings from "../components/Account/Settings/Settings";
import Profile from "../components/Account/Profile/Profile";
import Portal from "../components/Account/Portal/Portal";
import Store from "../components/Account/Store/Store";
import AuthorizeRoute from '../components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from '../components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';
import TermsOfService from "../components/Home/TermsOfService";
import About from "../components/Home/About";
import Help from "../components/Home/Help";
import HowItWorks from "../components/Home/HowItWorks";
import AlbyCallback from "../components/Account/AlbyCallback";

// Route component to manage app routes
class Routing extends Component {
    render() {
        return (
            <BrowserRouter>
                <AuthorizeRoute path="/chat" component={ChatMaster} />
                <AuthorizeRoute path="/account" component={Settings} />
                <AuthorizeRoute path="/portal" component={Portal} />
                <Route path="/s/:username" component={Store} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                <Route path="/how-it-works" component={HowItWorks} />
                <Route path="/help" component={Help} />
                <Route path="/about" component={About} />
                <Route path="/terms-of-service" component={TermsOfService} />
                <Route exact path="/" component={Home} />
                <Route path="/p/:username" component={Profile} />
                <Route exact path="/api/webhooks/alby" component={AlbyCallback} />
            </BrowserRouter>
        );
    }
}

export default Routing;
