import React, { Component } from "react";
import axios from "axios";

class NotificationSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            usePush: false,
            useEmail: false,
            notifyNewMessage: false,
            notifyNewSubscriber: false,
            notifyNewTip: false,
            notifyNewPurchase: false,
            saving: false
        };
    }

    async componentDidMount() {
        const response = await fetch(`api/account/c/${this.props.creatorId}/notification_settings`, {
            headers: { 'Authorization': `Bearer ${this.props.token}` }
        });

        if (response.status != 200) {
            alert("DO SOMETHING. Couldn't grab notification settings");
        }

        const profileSettings = await response.json();

        this.setState({
            loading: false,
            usePush: profileSettings.usePush,
            useEmail: profileSettings.useEmail,
            notifyNewMessage: profileSettings.notifyNewMessage,
            notifyNewSubscriber: profileSettings.notifyNewSubscriber,
            notifyNewTip: profileSettings.notifyNewTip,
            notifyNewPurchase: profileSettings.notifyNewPurchase
        });
    }

    saveForm = async () => {
        this.setState({
            saving: true
        });

        const formData = new FormData();

        formData.append("usePush", this.state.usePush);
        formData.append("useEmail", this.state.useEmail);
        formData.append("notifyNewMessage", this.state.notifyNewMessage);
        formData.append("notifyNewSubscriber", this.state.notifyNewSubscriber);
        formData.append("notifyNewTip", this.state.notifyNewTip);
        formData.append("notifyNewPurchase", this.state.notifyNewPurchase);

        const response = await axios.post(`/api/account/c/${this.props.creatorId}/notification_settings`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + this.props.token
            }
        });

        if (response.status != 200) {
            alert("DO SOMETHING. Couldn't set notification settings");
        }

        this.setState({
            saving: false
        });
    }

    render() {
        return (
            <>
                {this.state.loading &&
                    <p className="text-white">Loading...</p>
                }
                {!this.state.loading &&
                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="back-to-contact" onClick={this.props.handleCloseDetails} >
                                <span className="material-icons" style={{ color: "#495057" }}>arrow_back_ios</span>
                                <span className="text-white ml-2">Notification Settings</span>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="text-right sticky-top" style={{ padding: 0, margin: 0 }}>
                                {
                                    !this.state.saving &&
                                    <span className="material-icons text-primary" style={{ cursor: "pointer" }} onClick={this.saveForm}>save_as</span>
                                }
                                {
                                    this.state.saving &&
                                    <span className="text-white">Saving...</span>
                                }
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" id="usePush" checked={this.state.usePush} onChange={(e) => {
                                                this.setState({ usePush: !this.state.usePush })
                                            }} />
                                            <label className="custom-control-label" htmlFor="usePush">Get Push Notifications</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" id="useEmail" checked={this.state.useEmail} onChange={(e) => {
                                                this.setState({ useEmail: !this.state.useEmail })
                                            }} />
                                            <label className="custom-control-label" htmlFor="useEmail">Get Email Notifications</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" id="notifyNewMessage" checked={this.state.notifyNewMessage} onChange={(e) => {
                                                this.setState({ notifyNewMessage: !this.state.notifyNewMessage })
                                            }} />
                                            <label className="custom-control-label" htmlFor="notifyNewMessage">Notify new messages</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" id="notifyNewSubscriber" checked={this.state.notifyNewSubscriber} onChange={(e) => {
                                                this.setState({ notifyNewSubscriber: !this.state.notifyNewSubscriber })
                                            }} />
                                            <label className="custom-control-label" htmlFor="notifyNewSubscriber">Notify new subscribers</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" id="notifyNewTip" checked={this.state.notifyNewTip} onChange={(e) => {
                                                this.setState({ notifyNewTip: !this.state.notifyNewTip })
                                            }} />
                                            <label className="custom-control-label" htmlFor="notifyNewTip">Notify new tips</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" id="notifyNewPurchase" checked={this.state.notifyNewPurchase} onChange={(e) => {
                                                this.setState({ notifyNewPurchase: !this.state.notifyNewPurchase })
                                            }} />
                                            <label className="custom-control-label" htmlFor="notifyNewPurchase">Notify new purchases</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default NotificationSettings;