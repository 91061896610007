// Will be used for Adding and Editing Creator samples
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FileUploadWithPreview } from 'file-upload-with-preview/dist/file-upload-with-preview.esm';
import 'file-upload-with-preview/dist/file-upload-with-preview.min.css';
import axios from "axios";

// TODO: need validation when canAddVideo or canAddImage are not set

class SampleDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            title: '',
            order: 0,
            mimeType: '',
            saving: false,
            isVideo: false,
            uploader: null,
            mediaAssetId: 0,
            remoteLocation: ''
        };
    }

    componentDidMount() {
        if (this.props.sample !== null) {
            const isVideo = this.props.sample.mimeType.startsWith('video');
            let accept = 'image/jpeg,image/png';
            if (isVideo) {
                accept = 'video/*';
            }
            console.log(this.props.sample);
            this.setState({
                id: this.props.sample.creatorSampleId,
                title: this.props.sample.title,
                order: this.props.sample.order,
                mimeType: this.props.sample.mimeType,
                isVideo: isVideo,
                uploader: new FileUploadWithPreview('attachMedia', {
                    accept: accept,
                    maxFileCount: 1,
                    multiple: false,
                    presetFiles: [],
                    text: {
                        browse: 'Choose',
                        chooseFile: 'Upload a new ' + (isVideo ? 'video' : 'image'),
                        label: ''
                    }
                }),
                mediaAssetId: this.props.sample.mediaAssetId,
                remoteLocation: this.props.sample.remoteLocation
            });
        } else {
            this.setState({
                uploader: new FileUploadWithPreview('attachMedia', {
                    accept: 'image/jpeg,image/png,video/*',
                    maxFileCount: 1,
                    multiple: false,
                    presetFiles: [],
                    text: {
                        browse: 'Choose',
                        chooseFile: 'Upload a new image or video',
                        label: ''
                    }
                })
            });
        }
    }

    setModalShow = () => {
        this.props.hideSampleDetails();
    };

    updateSampleDetails = async () => {
        this.setState({
            saving: true
        });

        const formData = new FormData();
        formData.append("creatorSampleId", this.state.id);
        formData.append("title", this.state.title);
        formData.append("order", this.state.order);

        if (this.state.uploader.cachedFileArray &&
            this.state.uploader.cachedFileArray.length > 0) {
            formData.append("media", this.state.uploader.cachedFileArray[0]);
        }

        const response = await axios.post(`/api/account/c/${this.props.creatorId}/samples`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + this.props.token
            }
        });

        if (response.status != 200) {
            alert("DO SOMETHING. Couldn't update profile sample");
        }

        const profileSample = response.data;

        this.props.setSampleDetails({
            creatorSampleId: profileSample.sample.id,
            title: profileSample.sample.title,
            order: profileSample.sample.order,
            mimeType: profileSample.mimeType,
            mediaAssetId: profileSample.mediaAssetId,
            remoteLocation: profileSample.remoteLocation
        });

        this.setState({
            saving: false
        });

        this.props.hideSampleDetails();
    }

    setTitle = (e) => {
        this.setState({
            title: e.target.value
        });
    }

    setOrder = (e) => {
        const o = parseInt(e.target.value);
        this.setState({
            order: o
        });
    }

    render() {
        return (
            <Modal
                show={true}
                scrollable={true}
                onHide={() => this.setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Sample Details</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0 hide-scrollbar">
                    <div className="row ml-2 mb-1 mt-1 mr-2">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="displayName">Title</label>
                                <div className="d-flex justify-content-start">
                                    <input
                                        type="text"
                                        className="form-control form-control-md"
                                        value={this.state.title}
                                        onChange={this.setTitle}
                                        placeholder="Enter a title"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="displayName">Sort Order</label>
                                <div className="d-flex justify-content-start">
                                    <select className="form-control form-control-md"
                                        value={this.state.order} onChange={this.setOrder}>

                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className=" d-flex justify-content-start ml-3 mr-3 mb-1"
                    >
                        <div className="custom-file-container w-100" data-upload-id="attachMedia"></div>
                    </div>
                    <div className="d-flex justify-content-center mt-2 mb-2">

                        {
                            !this.state.saving &&
                            <>
                                <button
                                    type="button"
                                    className="btn btn-outline-danger mx-1"
                                    onClick={() => this.setModalShow(false)}
                                >
                                    Cancel
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-primary mx-1"
                                    onClick={this.updateSampleDetails}
                                >
                                    Update
                                </button>
                            </>
                        }
                        {
                            this.state.saving &&
                            <span className="text-white">Saving...</span>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default SampleDetails;