import React, { Component } from "react";

import axios from "axios";
import { QRCodeCanvas } from "qrcode.react";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";

import "./ChatMessage.css";

// A single chat message
class ChatMessage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            messageContent: JSON.parse(props.messageContentJson),
            payingOneClick: false,
            successfullyPaid: false
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.scrollToBottom();
        }, 10)
    }

    componentDidUpdate() {
        setTimeout(() => {
            this.props.scrollToBottom();
        }, 10)
    }

    payWithOneClick = async () => {
        this.setState({
            payingOneClick: true
        });
        try {
            const msg = this.state.messageContent.MessageText.split(" ");
            const numSats = parseInt(msg[1]);
            const bolt11 = this.state.messageContent.BOLT11;


            const formData = new FormData();

            formData.append("payRequest", bolt11);
            formData.append("numSats", numSats);

            const response = await axios.post("/api/albypay/pay", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + this.props.token
                }
            });

            console.log(response.data);

            if (response.status === 200) {
                this.setState({
                    successfullyPaid: true
                });
            }

        } catch (err) {
            console.log(err);
        } finally {
            this.setState({
                payingOneClick: false
            });
        }
    }

    render() {
        return (
            <div className={this.props.sentByCurrentUser ? "message self" : "message"}>
                <div className="message-wrapper">
                    <div className="message-content">
                        <span>{this.state.messageContent.MessageText}</span>
                        {
                            this.state.messageContent.Type === 2 &&
                            this.state.messageContent.Media &&
                            this.state.messageContent.Media.length > 0 &&
                            <div className="form-row" style={{ height: "250px" }}>
                                <LightgalleryProvider>
                                    {
                                        this.state.messageContent.Media.map((media) => (
                                            <div className="col" key={media.MediaAssetId}>
                                                <LightgalleryItem
                                                    group={"group1"}
                                                    src={media.RemoteLocation}
                                                    thumb={media.RemoteLocation}
                                                >
                                                    <img
                                                        alt="photo1"
                                                        src={media.RemoteLocation}
                                                        className="img-fluid"
                                                        style={{ maxHeight: "200px", width: "100%" }}
                                                    />
                                                </LightgalleryItem>
                                            </div>
                                        ))
                                    }
                                </LightgalleryProvider>
                            </div>
                        }
                        {
                            this.state.messageContent.Type === 3 &&
                            this.state.messageContent.BOLT11 &&
                            !this.state.successfullyPaid &&
                            <div>
                                <QRCodeCanvas value={this.state.messageContent.BOLT11} size={256} level={"H"} includeMargin={true} />
                                <div className="mt-1">
                                    <button className="btn btn-sm btn-secondary" role="button" onClick={() => { navigator.clipboard.writeText(this.state.messageContent.BOLT11) }}>Copy</button>
                                    {
                                        this.props.allowPayWithAlby &&
                                        !this.state.payingOneClick &&
                                        <button className="btn btn-sm btn-warning ml-2" role="button" onClick={this.payWithOneClick}>Pay with Alby</button>
                                    }
                                    {
                                        this.props.allowPayWithAlby &&
                                        this.state.payingOneClick &&
                                        <span className="ml-2">Paying invoice...</span>
                                    }
                                </div>
                            </div>
                        }
                        {
                            this.state.messageContent.Type === 3 &&
                            this.state.messageContent.BOLT11 &&
                            this.state.successfullyPaid &&
                            <div>
                                <span>Invoice paid! Content will be released below</span>
                            </div>
                        }
                    </div>
                </div>
                <div className="message-options">
                    <span className="message-date">{this.props.dateReceived}</span>
                </div>
            </div>
        );
    }
}

export default ChatMessage;