import React, { Component } from "react";
import SampleDetails from "../../Modals/Account/Creator/SampleDetails";
import axios from "axios";

class ProfileSamples extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            images: [],
            video: null,
            canAddImage: false,
            canAddVideo: false,
            showDetails: false,
            selectedSample: null
        };
    }

    async componentDidMount() {

        const response = await fetch(`api/account/c/${this.props.creatorId}/samples`, {
            headers: { 'Authorization': `Bearer ${this.props.token}` }
        });

        if (response.status != 200) {
            alert("DO SOMETHING. Couldn't grab profile samples");
        }

        const profileSamples = await response.json();
        let imageCount = 0;
        let videoCount = 0;

        for (let i = 0; i < profileSamples.length; i++) {
            if (profileSamples[i].mimeType.startsWith('video')) {
                this.setState({ video: profileSamples[i] });
                videoCount++;
            } else {
                this.setState({ images: [...this.state.images, profileSamples[i]] });
                imageCount++;
            }
        }

        this.setState({
            loading: false,
            canAddVideo: videoCount < 1,
            canAddImage: imageCount < 4
        });
    }

    addSample = () => {
        this.setState({
            showDetails: true
        });
    }

    showDetails = (id) => {

        let sample = null;
        if (this.state.video && this.state.video.creatorSampleId === id) {
            sample = this.state.video;
        } else if (this.state.images && this.state.images.length > 0) {
            for (let index = 0; index < this.state.images.length; index++) {
                if (this.state.images[index].creatorSampleId === id) {
                    sample = this.state.images[index];
                    break;
                }
            }
        }

        this.setState({
            showDetails: true,
            selectedSample: sample
        });
    }

    hideSampleDetails = () => {
        this.setState({
            showDetails: false,
            selectedSample: null
        });
    }

    setSampleDetails = (obj) => {

        if (this.state.video &&
            this.state.video.creatorSampleId === obj.creatorSampleId) {
            this.setState({
                video: obj,
                canAddVideo: false
            });

        } else if (this.state.images) {
            const images = this.state.images;
            let found = false;
            for (let index = 0; index < images.length; index++) {
                if (images[index].creatorSampleId === obj.creatorSampleId) {
                    images[index] = obj;
                    this.setState({
                        images: images
                    });
                    found = true;
                    break;
                }
            }

            if (!found) {

                if (images.length >= 3) {
                    this.setState({
                        canAddImage: false
                    });
                }

                this.setState({
                    images: [...images, obj]
                });
            }
        }
    }

    deleteSample = async (creatorSampleId) => {

        if (confirm('Are you sure you want to delete this sample?')) {
            const response = await axios.delete(`/api/account/c/${this.props.creatorId}/samples/${creatorSampleId}`, {
                headers: {
                    "Authorization": "Bearer " + this.props.token
                }
            });

            if (response.status != 200) {
                alert("DO SOMETHING. Couldn't delete profile sample");
            } else {
                let removedFromState = false;
                if (this.state.video && this.state.video.creatorSampleId === creatorSampleId) {
                    this.setState({
                        video: null,
                        canAddVideo: true
                    });

                    removedFromState = true;
                }
                if (!removedFromState && this.state.images) {
                    const images = this.state.images;
                    let index = -1;
                    for (let i = 0; i < images.length; i++) {
                        if (images[i].creatorSampleId === creatorSampleId) {
                            index = i;
                            break;
                        }
                    }

                    if (index >= 0) {
                        images.splice(index, 1);
                        this.setState({
                            images: images,
                            canAddImage: true
                        });
                    }
                }
            }
        }
    }


    render() {
        return (
            <>
                {this.state.loading &&
                    <p className="text-white">Loading...</p>
                }
                {!this.state.loading &&
                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="back-to-contact" onClick={this.props.handleCloseDetails} >
                                <span className="material-icons" style={{ color: "#495057" }}>arrow_back_ios</span>
                                <span className="text-white ml-2">Profile Samples</span>
                            </div>
                        </div>
                        <div className="card-body">
                            {(this.state.canAddImage || this.state.canAddVideo) &&
                                <div className="text-left mb-1">
                                    <span className="material-icons text-primary" style={{ cursor: "pointer" }} onClick={this.addSample}>add_circle_outline</span>
                                </div>
                            }
                            <div className="row">
                                {
                                    this.state.images &&
                                    this.state.images.map((sample) => (
                                        <div className="mb-2 mt-1 col-md-3 col-sm-6 text-white" key={sample.mediaAssetId}>
                                            <div className="row">
                                                <div className="col-12">
                                                    <span className="text-white small">{sample.title}</span>
                                                </div>
                                                <div className="col-12 mb-1">
                                                    <img src={sample.remoteLocation} className="img-fluid" />
                                                </div>
                                                <div className="col-12 text-center mt-1">
                                                    <button
                                                        className="btn btn-danger"
                                                        type="button"
                                                        onClick={() => this.deleteSample(sample.creatorSampleId)}
                                                    >
                                                        <span className="text-white">Delete</span>
                                                    </button>
                                                    <button
                                                        className="btn btn-primary ml-2"
                                                        type="button"
                                                        onClick={() => this.showDetails(sample.creatorSampleId)}>
                                                        <span className="text-white">Edit</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    ))
                                }
                                {
                                    this.state.video &&
                                    <>
                                        <div className="col-12 text-white mt-2">
                                            <div className="row">
                                                <hr className="w-100" />
                                                <div className="col-12">
                                                    <span className="text-white small">{this.state.video.title}</span>
                                                </div>
                                                <div className="col-lg-6">
                                                    <video width="100%" controls>
                                                        <source src={this.state.video.remoteLocation} type={this.state.video.mimeType} />
                                                    </video>
                                                    <div className="col-12 text-center mt-1">
                                                        <button
                                                            className="btn btn-danger"
                                                            type="button">
                                                            <span className="text-white">Delete</span>
                                                        </button>
                                                        <button
                                                            className="btn btn-primary ml-2"
                                                            type="button"
                                                            onClick={() => this.showDetails(this.state.video.creatorSampleId)}>
                                                            <span className="text-white">Edit</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                }

                {
                    this.state.showDetails &&
                    <SampleDetails
                        hideSampleDetails={this.hideSampleDetails}
                        setSampleDetails={this.setSampleDetails}
                        sample={this.state.selectedSample}
                        canAddVideo={this.state.canAddVideo}
                        canAddImage={this.state.canAddImage}
                        token={this.props.token}
                        creatorId={this.props.creatorId}
                    />
                }
            </>
        );
    }
}

export default ProfileSamples;