import React, { Component } from "react";
import axios from "axios";
import ChangeAvatar from "../../Modals/Account/Creator/ChangeAvatar";

class FanSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            saving: false,
            profilePhotoUri: '',
            showChangeAvatar: false,
            avatarFile: null
        };
    }

    async componentDidMount() {

        const response = await fetch(`/api/account/u/${this.props.userAccountId}/fan_settings`, {
            headers: { 'Authorization': `Bearer ${this.props.token}` }
        });

        if (response.status != 200) {
            alert("DO SOMETHING. Couldn't grab fan settings");
        }

        const fanSettings = await response.json();
        this.setState({
            loading: false
        });

        this.setState({
            profilePhotoUri: fanSettings.avatar
        });
    }

    setAvatar = (obj) => {
        if (obj && obj.avatar) {
            this.setState({
                profilePhotoUri: URL.createObjectURL(obj.avatar),
                avatarFile: obj.avatar
            });
        }
    }

    showChangeAvatar = () => {
        this.setState({
            showChangeAvatar: true
        });
    }

    hideChangeAvatar = () => {
        this.setState({
            showChangeAvatar: false
        });
    }

    saveForm = async () => {
        this.setState({
            saving: true
        });

        if (this.state.avatarFile) {

            const formData = new FormData();
            formData.append("avatar", this.state.avatarFile);

            const response = await axios.post(`/api/account/u/${this.props.userAccountId}/fan_settings`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + this.props.token
                }
            });

            if (response.status != 200) {
                alert("DO SOMETHING. Couldn't set fan settings");
            }

        }
        this.setState({
            saving: false
        });
    }

    render() {
        return (
            <>
                {this.state.loading &&
                    <p className="text-white">Loading...</p>
                }
                {!this.state.loading &&
                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="back-to-contact" onClick={this.props.handleCloseDetails} >
                                <span className="material-icons" style={{ color: "#495057" }}>arrow_back_ios</span>
                                <span className="text-white ml-2">Fan Settings</span>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="text-right sticky-top" style={{ padding: 0, margin: 0 }}>
                                {
                                    !this.state.saving &&
                                    <span className="material-icons text-primary" style={{ cursor: "pointer" }} onClick={this.saveForm}>save_as</span>
                                }
                                {
                                    this.state.saving &&
                                    <span className="text-white">Saving...</span>
                                }
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <a className="text-white small" href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.showChangeAvatar()
                                    }}>
                                        <div className="avatar avatar-xl">
                                            <img
                                                className="avatar-img"
                                                src={this.state.profilePhotoUri}
                                                alt="" />
                                        </div><br />
                                        Change avatar</a>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col">
                                    <h3>Integrations</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 text-left">
                                    <a className="btn btn-primary" href="https://getalby.com/oauth?client_id=uqkVrQ6FNj&response_type=code&redirect_uri=https%3A%2F%2Fonlysats.app%2Fapi%2Fwebhooks%2Falby&scope=invoices:create%20payments:send%20balance:read">Link Alby for easy payments</a>
                                    <p className="small text-white mt-1">
                                        <em>
                                            Linking your OnlySats account with Alby allows for a streamlined 1-click payment solution without the need to scan QR codes or copy BOLT11 pay requests.
                                        </em>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.showChangeAvatar &&
                    <ChangeAvatar
                        hideChangeAvatar={this.hideChangeAvatar}
                        setAvatar={this.setAvatar}
                    />
                }
            </>
        );
    }
}

export default FanSettings;