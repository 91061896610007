import React, { Component } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import Footer from "./Footer";


class Help extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <div className={"main-layout vh-100 card-bg-1"}>
                <div className="d-flex flex-column h-100 hide-scrollbar">
                    <div className="container d-flex flex-column">
                        <div className="row no-gutters text-center align-items-center justify-content-center">
                            <div className="col-12">
                                <div className="row mb-3">
                                    <div className="col-12 mb-2">
                                        <img src="/images/logo2.png" alt="Only Sats" className="img-fluid m-2" />
                                        <h1 className="m-2" style={{ fontSize: "18px" }}>Help Center</h1>
                                    </div>
                                    <div className="col-md-8 offset-md-2">
                                        <div className="mt-2">
                                            <Accordion defaultActiveKey="0" className="text-left text-white small">
                                                <Card>
                                                    <Card.Header>
                                                        <Accordion.Toggle as={Card.Header} eventKey="0">
                                                            Q1
                                                        </Accordion.Toggle>
                                                    </Card.Header>

                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body>
                                                            <p>
                                                                A1
                                                            </p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                                <Card>
                                                    <Card.Header>
                                                        <Accordion.Toggle as={Card.Header} eventKey="1">
                                                            Q2
                                                        </Accordion.Toggle>
                                                    </Card.Header>

                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body>
                                                            <p>
                                                                A2
                                                            </p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Help;