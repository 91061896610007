import React, { Component } from "react";
import axios from "axios";
import ChangeAvatar from "../../Modals/Account/Creator/ChangeAvatar";

class ProfileSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            displayName: '',
            email: '',
            location: '',
            wishlist: '',
            about: '',
            keywords: '',
            profilePhotoUri: '',
            apiKey: '',
            strikeHandle: '',
            bitcoinPaymentProcessor: 0,
            showChangeAvatar: false,
            avatarFile: null,
            saving: false
        };
    }

    async componentDidMount() {

        const response = await fetch(`api/account/c/${this.props.creatorId}/profile_settings`, {
            headers: { 'Authorization': `Bearer ${this.props.token}` }
        });

        if (response.status != 200) {
            alert("DO SOMETHING. Couldn't grab profile settings");
        }

        const data = await response.json();

        this.setState({
            loading: false,
            displayName: data.profile.displayName,
            email: data.profile.email,
            location: data.profile.location,
            wishlist: data.profile.amazonWishlist,
            about: data.profile.about,
            keywords: data.profile.keywords,
            profilePhotoUri: data.profile.profilePhotoUri,
            apiKey: data.apiKey,
            strikeHandle: data.strikeHandle,
            bitcoinPaymentProcessor: data.bitcoinPaymentProcessor
        });
    }

    showChangeAvatar = () => {
        this.setState({
            showChangeAvatar: true
        });
    }

    hideChangeAvatar = () => {
        this.setState({
            showChangeAvatar: false
        });
    }

    saveForm = async () => {
        this.setState({
            saving: true
        });

        const formData = new FormData();
        formData.append("displayName", this.state.displayName);
        formData.append("email", this.state.email);
        formData.append("about", this.state.about);
        formData.append("amazonWishlist", this.state.wishlist);
        formData.append("location", this.state.location);
        formData.append("keywords", this.state.keywords);
        formData.append("apiKey", this.state.apiKey);
        formData.append("strikeHandle", this.state.strikeHandle);
        formData.append("bitcoinPaymentProcessor", this.state.bitcoinPaymentProcessor);

        if (this.state.avatarFile) {
            formData.append("avatar", this.state.avatarFile);
        }

        const response = await axios.post(`/api/account/c/${this.props.creatorId}/profile_settings`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + this.props.token
            }
        });

        if (response.status != 200) {
            alert("DO SOMETHING. Couldn't set profile settings");
        }

        this.setState({
            saving: false
        });
    }

    setAvatar = (obj) => {
        if (obj && obj.avatar) {
            this.setState({
                profilePhotoUri: URL.createObjectURL(obj.avatar),
                avatarFile: obj.avatar
            });
        }
    }

    setBitcoinPaymentProcessor = (e) => {
        const o = parseInt(e.target.value);
        this.setState({
            bitcoinPaymentProcessor: o
        });
    }

    render() {
        return (
            <>
                {this.state.loading &&
                    <p className="text-white">Loading...</p>
                }
                {!this.state.loading &&
                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="back-to-contact" onClick={this.props.handleCloseDetails} >
                                <span className="material-icons" style={{ color: "#495057" }}>arrow_back_ios</span>
                                <span className="text-white ml-2">Account Settings</span>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="text-right sticky-top" style={{ padding: 0, margin: 0 }}>
                                {
                                    !this.state.saving &&
                                    <span className="material-icons text-primary" style={{ cursor: "pointer" }} onClick={this.saveForm}>save_as</span>
                                }
                                {
                                    this.state.saving &&
                                    <span className="text-white">Saving...</span>
                                }
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <a className="text-white small" href="#" onClick={(e) => {
                                        e.preventDefault();
                                        this.showChangeAvatar()
                                    }}>
                                        <div className="avatar avatar-xl">
                                            <img
                                                className="avatar-img"
                                                src={this.state.profilePhotoUri}
                                                alt="" />
                                        </div><br />
                                        Change avatar</a>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="displayName">Display Name</label>
                                        <div className="d-flex justify-content-start">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="displayName"
                                                placeholder=""
                                                value={this.state.displayName}
                                                onChange={(e) => {
                                                    this.setState({ displayName: e.target.value });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <div className="d-flex justify-content-start">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                placeholder=""
                                                value={this.state.email}
                                                onChange={(e) => {
                                                    this.setState({ email: e.target.value });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="location">Location</label>
                                        <div className="d-flex justify-content-start">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="location"
                                                placeholder=""
                                                value={this.state.location}
                                                onChange={(e) => {
                                                    this.setState({ location: e.target.value });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group">
                                        <label htmlFor="wishlist">Amazon Wishlist Link</label>
                                        <div className="d-flex justify-content-start">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="wishlist"
                                                placeholder=""
                                                value={this.state.wishlist}
                                                onChange={(e) => {
                                                    this.setState({ wishlist: e.target.value });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="keywords">Keywords</label>
                                        <div className="d-flex justify-content-start">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="keywords"
                                                placeholder="Describe yourself or content"
                                                value={this.state.keywords}
                                                onChange={(e) => {
                                                    this.setState({ keywords: e.target.value });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label htmlFor="about">About</label>
                                        <div className="d-flex justify-content-start">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="about"
                                                placeholder=""
                                                value={this.state.about}
                                                onChange={(e) => {
                                                    this.setState({ about: e.target.value });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="about">Bitcoin Payment Processor</label>
                                        <div className="d-flex justify-content-start">
                                            <select className="form-control"
                                                value={this.state.bitcoinPaymentProcessor} onChange={this.setBitcoinPaymentProcessor}>
                                                <option value="3">Strike</option>
                                                <option value="1">OpenNode</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        {
                                            this.state.bitcoinPaymentProcessor == 1 &&
                                            <>
                                                <label htmlFor="apiKey">API Key</label>
                                                <div className="d-flex justify-content-start">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="apiKey"
                                                        placeholder=""
                                                        value={this.state.apiKey}
                                                        onChange={(e) => {
                                                            this.setState({ apiKey: e.target.value });
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        }
                                        {
                                            this.state.bitcoinPaymentProcessor == 3 && 
                                            <>
                                                <label htmlFor="strikeHandle">Username</label>
                                                <div className="d-flex justify-content-start">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="strikeHandle"
                                                        placeholder="Enter your Strike username"
                                                        value={this.state.strikeHandle}
                                                        onChange={(e) => {
                                                            this.setState({ strikeHandle: e.target.value });
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.showChangeAvatar &&
                    <ChangeAvatar
                        hideChangeAvatar={this.hideChangeAvatar}
                        setAvatar={this.setAvatar}
                    />
                }
            </>
        );
    }
}

export default ProfileSettings;