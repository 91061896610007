import React, { Component } from "react";
import axios from "axios";

import "./ChatMaster.css";

import ChatSidebar from "./ChatSidebar";
import ChatCenterbar from "./ChatCenterbar";
import Header from "../NavBar/Header";
import NavBar from "../NavBar/NavBar";
import { HubConnectionBuilder } from '@microsoft/signalr';
import authService from '../api-authorization/AuthorizeService';
import queryString from "query-string";

// Overall container for the Chat function
class ChatMaster extends Component {

    constructor(props) {
        super(props);

        this.state = {
            /* Chat detail window open */
            openChat: false,
            /* All of the messages for the selected chat */
            messages: [],
            formattingMessages: false,
            /* The current message being typed */
            messageInput: '',
            /* All chatSessions for the current user (even with no chat history) */
            chatSessions: [],
            /* Search string to find contact */
            findContact: '',
            openEmojis: false,
            attachedMedia: [],
            messagePrice: 0,
            sendingMessage: false,
            connection: null,
            /* ChatSession = (Creator, Patron) DM */
            chatSessionId: 0,
            creatorId: 0,
            theirUsername: '',
            /* Get from Claims */
            myUserAccountId: 0,
            myUsername: '',
            token: '',
            /* Paywall state */
            bolt11: '',
            bolt11ExpiresAt: 0,
            houseFeeSats: 0,
            showPaywall: false,
            isAuthenticated: false,
            claims: null,
            /* Loading states */
            loadingChatSessions: true,
            allowPayWithAlby: false
        };
    }

    async componentDidMount() {
        const authenticated = await authService.isAuthenticated();

        if (!authenticated) {
            const baseUri = window.location.origin;
            window.location.href = `${baseUri}/authentication/login?returnUrl=${encodeURI(`${baseUri}/chat`)}`;
        }

        const connection = new HubConnectionBuilder()
            .withUrl(window.location.origin + "/chatHub")
            .withAutomaticReconnect()
            .build();

        connection.on("ReceiveMessage", async (senderUsername, avatarUrl, messageContentJson, dateReceived, eventId) => {

            try {

                const sentByCurrentUser = (senderUsername === this.state.myUsername);

                var msg = {
                    messageContentJson,
                    dateReceived,
                    sentByCurrentUser,
                    eventId,
                    senderUsername,
                    avatarUrl
                }

                this.setState({
                    messages: [...this.state.messages, msg]
                });

                setTimeout(() => {
                    this.scrollToBottom();
                }, 500)

            } catch (err) {
                console.log("ERROR: ", err);
            }
        });

        await connection.start();

        const token = await authService.getAccessToken();
        this.setState({
            token: token,
            isAuthenticated: authenticated
        });

        const response = await fetch('api/claims', {
            headers: { 'Authorization': `Bearer ${token}` }
        });

        if (response.status != 200) {
            const baseUri = window.location.origin;
            window.location.href = `${baseUri}/authentication/login?returnUrl=${encodeURI(`${baseUri}/chat`)}`;
        }

        const claims = await response.json();

        const params = queryString.parse(this.props.location.search);
        if (params.creatorId) {

            const chatSessionResponse = await fetch('api/chat/session?creatorId=' + params.creatorId, {
                headers: { 'Authorization': `Bearer ${this.state.token}` }
            });

            const chatSession = await chatSessionResponse.json();

            if (chatSession && chatSession.chatSessionId > 0) {
                window.location = "/chat?chatSessionId=" + chatSession.chatSessionId;
            } else {
                window.location = "/chat";
            }
        }

        const chatSessions = await this.getChatSessions();

        this.setState({
            chatSessions: chatSessions,
            connection: connection,
            myUserAccountId: parseInt(claims.UserAccountId[0]),
            myUsername: claims.Username[0],
            claims: claims,
            loadingChatSessions: false
        });

        if (params.chatSessionId) {
            await this.clickActiveChatSession(params.chatSessionId);
        }

        //console.log(this.state);
    }

    onEmojiClick = (event, emojiObject) => {
        this.setState({
            messageInput: this.state.messageInput + emojiObject.emoji,
            openEmojis: false
        });
    }

    handleOpenEmojis = () => {
        this.setState({
            openEmojis: !this.state.openEmojis
        });
    }

    // TODO: Rename
    changeFindContact = (e) => {
        this.setState({
            findContact: e.target.value
        });
    }

    changeMessageInput = (e) => {
        this.setState({
            messageInput: e.target.value
        });
    }

    setOpenChat = (val) => {
        this.setState({
            openChat: val
        });
    }

    setActiveChatSession = (val) => {
        this.setState({
            chatSessionId: val
        });
    }

    clickActiveChatSession = async (id) => {

        this.setState({
            messages: []
        });

        const chatSessionResponse = await fetch('api/chat/session?chatSessionId=' + id, {
            headers: { 'Authorization': `Bearer ${this.state.token}` }
        });

        const chatSession = await chatSessionResponse.json();

        //console.log("CHAT SESSION", chatSession);

        if (chatSession.showHouseFeePaywall === true) {

            document.getElementById("center-bar").style.display = "block";

            this.setState({
                findContact: '',
                openChat: true,
                openEmojis: false,
                showPaywall: true,
                messageInput: '',
                messages: [],
                chatSessionId: chatSession.chatSessionId,
                creatorId: chatSession.creatorId,
                bolt11: chatSession.bolT11,
                houseFeeSats: chatSession.satoshis,
                theirUsername: chatSession.theirUsername,
                bolt11ExpiresAt: chatSession.expiresAtTimestamp
            });

        } else {
            this.setState({
                formattingMessages: true,
                findContact: '',
                openChat: true,
                showPaywall: false,
                openEmojis: false,
                messageInput: '',
                chatSessionId: chatSession.chatSessionId,
                creatorId: chatSession.creatorId,
                theirUsername: chatSession.theirUsername,
                allowPayWithAlby: chatSession.allowPayWithAlby
            });

            await this.state.connection.invoke("JoinRoom", {
                userAccountId: this.state.myUserAccountId,
                chatSessionId: parseInt(id)
            });

            document.getElementById("center-bar").style.display = "block";

            const messagesResponse = await fetch('api/chat/messages?chatSessionId=' + id, {
                headers: { 'Authorization': `Bearer ${this.state.token}` }
            });

            const messages = await messagesResponse.json();
            //console.log(messages);

            this.setState({
                messages: messages,
                formattingMessages: false
            });

            setTimeout(() => {
                this.scrollToBottom();
            }, 500)
        }
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    scrollToBottom = () => {

        const messagesEnd = document.getElementById('messagesEndMarker');
        if (messagesEnd) {
            messagesEnd.scrollIntoView({ behavior: "smooth" });
        }
    }

    setAttachedMedia = (media) => {
        this.setState({
            attachedMedia: media
        });
    }

    setMessagePrice = (price) => {
        this.setState({
            messagePrice: price
        });
    }

    onSend = async () => {
        try {
            if (this.state.messageInput.length > 0) {

                this.setState({
                    sendingMessage: true
                });

                const formData = new FormData();
                formData.append("chatSessionId", this.state.chatSessionId.toString());
                if (this.state.messagePrice > 0) {
                    formData.append("costInSatoshis", this.state.messagePrice.toString())
                }

                formData.append("message", this.state.messageInput);

                if (this.state.attachedMedia && this.state.attachedMedia.length > 0) {

                    for (let index = 0; index < this.state.attachedMedia.length; index++) {
                        formData.append("attachments", this.state.attachedMedia[index]);
                    }
                }

                const response = await axios.post("/api/chat/messages", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": "Bearer " + this.state.token
                    }
                });

                //console.log("HTTP response:", response);


                this.setState({
                    attachedMedia: [],
                    messagePrice: 0,
                    messageInput: '',
                    openEmojis: false
                });
            }
        } catch (err) {
            alert(err);
        } finally {
            this.setState({
                sendingMessage: false
            });
        }
    }

    async getChatSessions() {

        const response = await fetch('api/chat/connections', {
            headers: { 'Authorization': `Bearer ${this.state.token}` }
        });

        const responseJson = await response.json();

        let chatSessions = [];

        if (responseJson.connections) {
            for (let i = 0; i < responseJson.connections.length; i++) {
                const connection = responseJson.connections[i];

                chatSessions.push({
                    contactName: connection.username,
                    contactAvatar: connection.avatarUrl,
                    id: connection.chatSessionId,
                    isFromPatron: connection.isFromPatron,
                    lastMessage: connection.lastMessage,
                    time: connection.time
                })
            }
        }


        return chatSessions;
    }

    render() {
        return (
            <div className={"main-layout vh-100"}>
                <div className="hide-scrollbar h-100" id="chats-content" style={{ backgroundColor: '#495057' }}>
                    <div className="d-flex flex-column h-100">
                        <div className="hide-scrollbar">
                            {
                                !this.state.loadingChatSessions &&

                                <Header
                                    isAuthenticated={this.state.isAuthenticated}
                                    token={this.state.token}
                                />
                            }
                            <div className="chat-master-component">
                                <div className="chat-master-component-container">
                                    <div className="chat-master-component-wrapper">
                                        {
                                            /*
                                                ChatSidebar will display the search box and contact
                                                list that can be selected
                                            */
                                        }
                                        <ChatSidebar
                                            findContact={this.state.findContact}
                                            chatSessions={this.state.chatSessions}
                                            chatSessionId={this.state.chatSessionId}
                                            clickActiveChatSession={this.clickActiveChatSession}
                                            changeFindContact={this.changeFindContact}
                                            connection={this.state.connection}
                                            loadingChatSessions={this.state.loadingChatSessions}
                                        />

                                        {
                                            /*
                                                ChatCenterbar will display the chat details and an initial
                                                state "Select contact to begin chatting"
                                            */
                                        }
                                        <ChatCenterbar
                                            onSend={this.onSend}
                                            chatSessions={this.state.chatSessions}
                                            messages={this.state.messages}
                                            formattingMessages={this.state.formattingMessages}
                                            openChat={this.state.openChat}
                                            chatSessionId={this.state.chatSessionId}
                                            messageInput={this.state.messageInput}
                                            changeMessageInput={this.changeMessageInput}
                                            onEmojiClick={this.onEmojiClick}
                                            handleOpenEmojis={this.handleOpenEmojis}
                                            openEmojis={this.state.openEmojis}
                                            clickActiveChatSession={this.clickActiveChatSession}
                                            setOpenChat={this.setOpenChat}
                                            setActiveChatSession={this.setActiveChatSession}
                                            attachedMedia={this.state.attachedMedia}
                                            messagePrice={this.state.messagePrice}
                                            setAttachedMedia={this.setAttachedMedia}
                                            setMessagePrice={this.setMessagePrice}
                                            sendingMessage={this.state.sendingMessage}
                                            connection={this.state.connection}
                                            bolt11={this.state.bolt11}
                                            bolt11ExpiresAt={this.state.bolt11ExpiresAt}
                                            houseFeeSats={this.state.houseFeeSats}
                                            showPaywall={this.state.showPaywall}
                                            theirUsername={this.state.theirUsername}
                                            token={this.state.token}
                                            claims={this.state.claims}
                                            creatorId={this.state.creatorId}
                                            allowPayWithAlby={this.state.allowPayWithAlby}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    /*!this.state.openChat*/ true &&
                    <NavBar activeMenu="chat" />
                }
                <div className="backdrop"></div>
            </div>
        );
    }
}

export default ChatMaster;