import React, { Component } from "react";
import Header from "../../NavBar/Header";
import Feed from "./Feed";
import CreateFeedPost from "./CreateFeedPost";
import SubscribeCreator from "../../Modals/SubscribeCreator/SubscribeCreator";
import TipCreator from "../../Modals/TipCreator/TipCreator";

import { Lock, Person, LocationOn, Redeem } from '@material-ui/icons';

import { ReactComponent as ChatSvg } from "../../../assets/media/heroicons/outline/chat.svg";
import { ReactComponent as CartSvg } from "../../../assets/media/heroicons/outline/shopping-cart.svg";
import { ReactComponent as StarSvg } from "../../../assets/media/heroicons/outline/star.svg"
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import { BitcoinIcon } from '@bitcoin-design/bitcoin-icons-react/filled';
import "lightgallery.js/dist/css/lightgallery.css";

import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import authService from '../../api-authorization/AuthorizeService';
import axios from "axios";

// Public profile for Creators only
class Profile extends Component {

    constructor(props) {
        super(props);

        // TODO: need to get all profile data including feed from username

        this.state = {
            creatorId: 0,
            samples: [],
            videoTeaser: null,
            username: '',
            displayName: '',
            about: '',
            location: '',
            wishlist: '',
            avatar: '',
            isAuthenticated: false,
            token: '',
            callbackUrl: '',
            showMore: false,
            loading: true,
            feed: [],
            isOwner: false,
            isPatron: true,
            isSubscribed: false,
            newFeedPostInput: '',
            attachedMedia: [],
            showTipCreator: false,
            showSubscribeCreator: false,
            chatLink: '',
            creatingFeedPost: false,
            subscriptionPackages: []
        }
    }

    async componentDidMount() {

        const authenticated = await authService.isAuthenticated();
        const { username } = this.props.match.params;

        const profileUri = authenticated ? `/api/profile/c/${username}` : `/api/profile/public/c/${username}`;

        const token = await authService.getAccessToken();

        const headers = authenticated ? { headers: { 'Authorization': `Bearer ${token}` } } : {};

        const response = await fetch(profileUri, headers);
        const callbackUrl = `${window.location.origin}/p/${username}`;

        if (response.status != 200) {
            const baseUri = window.location.origin;
            window.location.href = `${baseUri}/authentication/login?returnUrl=${encodeURI(callbackUrl)}`;
        }

        const profile = await response.json();

        //console.log("Profile", profile);

        const claimsResponse = await fetch('api/claims', {
            headers: { 'Authorization': `Bearer ${token}` }
        });

        if (claimsResponse.status == 200) {
            const claims = await claimsResponse.json();

            //console.log(claims);
            const isPatron = claims.UserRole[0] === 'PATRON';

            this.setState({
                isPatron: isPatron,
                isOwner: profile.creator.username === claims.Username[0],
                isSubscribed: isPatron && profile.feedStart !== null,
                chatLink: isPatron ? `/chat?creatorId=${profile.creator.creatorId}` : '/chat'
            });
        }

        for (let sampleIndex = 0; sampleIndex < profile.creator.samples.length; sampleIndex++) {
            if (profile.creator.samples[sampleIndex].mimeType.startsWith('video/')) {
                this.setState({
                    videoTeaser: profile.creator.samples[sampleIndex]
                });

                break;
            }
        }

        this.setState({
            username: username,
            creatorId: profile.creator.creatorId,
            avatar: profile.creator.avatarUrl,
            isAuthenticated: authenticated,
            callbackUrl: callbackUrl,
            token: token,
            loading: false,
            feed: profile.feed,
            samples: profile.creator.samples,
            displayName: profile.creator.displayName,
            about: profile.creator.aboutHtml,
            wishlist: profile.creator.amazonWishList,
            location: profile.creator.location,
            subscriptionPackages: profile.creator.subscriptionPackages
        });
    }

    setAttachedMedia = (media) => {
        this.setState({
            attachedMedia: media
        });
    }

    changeMessageInput = (e) => {
        this.setState({
            newFeedPostInput: e.target.value
        });
    }

    toggleInfoDetails = () => {

        const showMore = !this.state.showMore;

        if (showMore) {
            document.getElementById('infoTeaser').style.display = 'none';
            document.getElementById('infoFull').style.display = 'block';
        } else {
            document.getElementById('infoTeaser').style.display = 'block';
            document.getElementById('infoFull').style.display = 'none';
        }

        this.setState({
            showMore: showMore
        });
    }

    toggleTipCreator = () => {
        this.setState({
            showTipCreator: !this.state.showTipCreator,
        });
    };

    toggleSubscribeCreator = () => {

        if (!this.state.isAuthenticated) {
            const baseUri = window.location.origin;
            window.location.href = `${baseUri}/authentication/login?returnUrl=${encodeURI(this.state.callbackUrl)}`;
        } else {
            this.setState({
                showSubscribeCreator: !this.state.showSubscribeCreator
            });
        }
    }

    onCreateNewPost = async () => {
        try {
            if (this.state.newFeedPostInput.length > 0) {

                this.setState({
                    creatingFeedPost: true
                });

                const formData = new FormData();

                formData.append("message", this.state.newFeedPostInput);

                if (this.state.attachedMedia && this.state.attachedMedia.length > 0) {

                    for (let index = 0; index < this.state.attachedMedia.length; index++) {
                        formData.append("attachments", this.state.attachedMedia[index]);
                    }
                }

                const response = await axios.post("/api/profile/feed_post", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": "Bearer " + this.state.token
                    }
                });

                //console.log("HTTP response:", response);

                this.setState({
                    attachedMedia: [],
                    newFeedPostInput: '',
                });
            }
        } catch (err) {
            alert(err);
        } finally {
            this.setState({
                creatingFeedPost: false
            });
        }
    }

    render() {
        return (
            <>
                <div className={"main-layout vh-100"}>
                    <div className="hide-scrollbar h-100" id="profile-content" style={{ backgroundColor: '#495057' }}>
                        <div className="d-flex flex-column h-100">
                            <div className="hide-scrollbar">
                                {!this.state.loading &&
                                    <Header
                                        isAuthenticated={this.state.isAuthenticated}
                                        token={this.state.token}
                                    />
                                }
                                <div className="container-xl mt-1">
                                    {
                                        !this.state.loading &&
                                        <div className="row">
                                            <div className="col">
                                                <div className="card">
                                                    <div className="d-flex flex-column align-items-center">
                                                        <div className="avatar avatar-xxl mx-5 mt-2">
                                                            <img
                                                                className="avatar-img"
                                                                src={this.state.avatar}
                                                                alt=""
                                                            ></img>
                                                        </div>
                                                        <h5 className="text-center">{this.state.displayName}<br />@{this.state.username}</h5>
                                                        {
                                                            this.state.isAuthenticated &&
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <div className="text-center">
                                                                    <a className="nav-link text-muted px-0" onClick={this.toggleSubscribeCreator}>
                                                                        <div className="btn btn-primary btn-icon rounded-circle text-light mx-2">
                                                                            <StarSvg className="injectable hw-20" />
                                                                        </div>
                                                                        <span className="small">Subscribe</span>
                                                                    </a>
                                                                </div>
                                                                <div className="text-center">
                                                                    <a className="nav-link text-muted px-0" href={this.state.chatLink}>
                                                                        <div className="btn btn-primary btn-icon rounded-circle text-light mx-2">
                                                                            <ChatSvg className="injectable hw-24" />
                                                                        </div>
                                                                        <span className="small">Chat</span>
                                                                    </a>
                                                                </div>

                                                                <div className="text-center">
                                                                    <a className="nav-link text-muted px-0" href={"/Store?creatorId=" + this.state.creatorId}>
                                                                        <div className="btn btn-primary btn-icon rounded-circle text-light mx-2">
                                                                            <CartSvg className="injectable hw-20" />
                                                                        </div>
                                                                        <span className="small">Store</span>
                                                                    </a>
                                                                </div>
                                                                <div className="text-center">
                                                                    <a className="nav-link text-muted px-0" onClick={this.toggleTipCreator}>
                                                                        <div className="btn btn-primary btn-icon rounded-circle text-light mx-2">
                                                                            <BitcoinIcon className="injectable" style={{ height: "2rem", width: "2rem" }} />
                                                                        </div>

                                                                        <span className="small">Send Tip</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            !this.state.isAuthenticated &&
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <a role="button" className="btn btn-primary btn-block mb-2 text-uppercase font-weight-semibold" href={"/authentication/login?returnUrl=" + encodeURI(this.state.callbackUrl)}><Lock style={{ fill: "#FFF" }} /> Login to view content</a>
                                                            </div>
                                                        }
                                                        <div className="chat-info-group-content list-item-has-padding">
                                                            <ul className="list-group list-group-flush text-primary">
                                                                <li className="list-group-item border-0" id="infoTeaser" style={{ display: "block", cursor: "pointer" }}>
                                                                    <p className="mb-0">{this.state.about}</p>
                                                                    <a className="small" onClick={this.toggleInfoDetails}>More info</a>
                                                                </li>
                                                                <li className="list-group-item border-0" id="infoFull" style={{ display: "none", cursor: "pointer" }}>
                                                                    <p className="mb-0">{this.state.about}</p>
                                                                    <p className="mb-0 small"><LocationOn style={{ fill: "#FFF", height: "1rem" }} /> {this.state.location}</p>
                                                                    <p className="mb-0 small"><Redeem style={{ fill: "#FFF", height: "1rem" }} /> <a href={this.state.wishlist} target="_blank">Send me a gift</a></p>
                                                                    <a className="small" onClick={this.toggleInfoDetails}>Collapse info</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.isOwner &&
                                                    <div className="card mt-1 mb-1 text-white">
                                                        <div className=" mx-2">
                                                            <CreateFeedPost
                                                                setAttachedMedia={this.setAttachedMedia}
                                                                onCreateNewPost={this.onCreateNewPost}
                                                                attachedMedia={this.state.attachedMedia}
                                                                changeMessageInput={this.changeMessageInput}
                                                                messageInput={this.state.newFeedPostInput}
                                                                creatingFeedPost={this.state.creatingFeedPost}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                <div className="card mt-1">
                                                    <div className="container">
                                                        {
                                                            !this.state.loading &&
                                                            this.state.videoTeaser &&
                                                            <div className="row mt-2">
                                                                <div className="col-12">
                                                                    <video width="100%" controls>
                                                                        <source src={this.state.videoTeaser.remoteLocation} type={this.state.videoTeaser.mimeType} />
                                                                    </video>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="row mt-2">
                                                            <LightgalleryProvider>
                                                                {!this.state.loading &&
                                                                    this.state.samples &&
                                                                    this.state.samples.length > 0 &&
                                                                    this.state.samples.map((sample) => {
                                                                        if (sample.mimeType.startsWith('image/')) {
                                                                            return <div className="col-6 col-lg-3 mb-2" key={sample.creatorSampleId}>
                                                                                <LightgalleryItem
                                                                                    group={"group1"}
                                                                                    src={sample.remoteLocation}
                                                                                    thumb={sample.remoteLocation}
                                                                                >
                                                                                    <img
                                                                                        alt={sample.title}
                                                                                        src={sample.remoteLocation}
                                                                                        style={{ width: "100%" }}
                                                                                    />
                                                                                </LightgalleryItem>
                                                                            </div>;
                                                                        }

                                                                        return null;

                                                                    })}
                                                            </LightgalleryProvider>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card mt-1 mb-2 text-white">
                                                    <div className=" mx-2">
                                                        {
                                                            !this.state.loading &&
                                                            <Feed
                                                                feed={this.state.feed}
                                                                displayName={this.state.displayName}
                                                                isOwner={this.state.isOwner}
                                                                isPatron={this.state.isPatron}
                                                                isSubscribed={this.state.isSubscribed}
                                                                showSubscribeCreator={this.toggleSubscribeCreator}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.loading &&
                                        <p className="text-white">Loading...</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div >
                </div>

                {
                    this.state.showTipCreator &&
                    <TipCreator
                        hideTipCreator={this.toggleTipCreator}
                        creatorUsername={this.state.username}
                        token={this.state.token}
                        creatorId={this.state.creatorId}
                        suggestedTip={25000}
                    />
                }
                {
                    this.state.showSubscribeCreator &&
                    <SubscribeCreator
                        hideSubscribeCreator={this.toggleSubscribeCreator}
                        creatorUsername={this.state.username}
                        subscriptionPackages={this.state.subscriptionPackages}
                        token={this.state.token}
                        creatorId={this.state.creatorId}
                    />
                }
            </>
        );
    }
}
export default Profile;
