import React, { Component } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { ReactComponent as RefreshSvg } from "../../assets/media/heroicons/outline/refresh.svg";
import CountdownTimer from "./CountdownTimer";

import "./ChatPaywall.css";

// Shows QR Code with House Fee invoice. Once paid
// chat detail will load
class ChatPaywall extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isCreator: false,
            secondsToExpiry: this.getDeltaSeconds(this.props.bolt11ExpiresAt),
            loading: true
        };
    }

    componentDidMount() {
        if (this.props.claims.UserRole[0] === 'CREATOR') {
            this.setState({
                isCreator: true
            });
        }
        this.setState({
            loading: false
        });
    }

    numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    getDeltaSeconds = (t) => {
        return t - Math.floor(Date.now() / 1000);
    }

    render() {
        return (
            <div id="chat-paywall" className=" hide-scrollbar">
                {
                    !this.state.loading &&

                    <div className="chat-paywall-container">
                        {
                            this.state.isCreator &&

                            <p>Waiting on Fan to pay invoice</p>
                        }
                        {
                            !this.state.isCreator &&
                            <>
                                <div className="mb-1">
                                    <h4>Pay {this.numberWithCommas(this.props.houseFeeSats)} sats to begin chatting with @{this.props.theirUsername}</h4>

                                    <CountdownTimer
                                        initialSeconds={this.state.secondsToExpiry}
                                    />
                                </div>
                                <div className="mb-2 text-center">
                                    <QRCodeCanvas value={this.props.bolt11.toUpperCase()} size={256} level={"H"} includeMargin={true} />
                                    <br />
                                    <button className="btn btn-sm btn-secondary" role="button" onClick={() => { navigator.clipboard.writeText(this.props.bolt11.toUpperCase()) }}>Copy</button>

                                    <p className="small">
                                        <small>Note: Please allow up to 2 minutes after payment is complete for chat to be available</small>
                                    </p>
                                </div>
                            </>
                        }
                    </div>
                }{
                    this.state.loading &&
                    <span className="text-white">Loading...</span>
                }
            </div>
        );
    }
}

export default ChatPaywall;