import React, { Component } from "react";
import Header from "../../NavBar/Header";
import authService from '../../api-authorization/AuthorizeService';


/* Homepage that contains all updates: Feeds, recommendations, etc. */
class Store extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            loading: true,
            token: ''
        };
    }

    async componentDidMount() {
        const authenticated = await authService.isAuthenticated();
        /*
        if (!authenticated) {
            const baseUri = window.location.origin;
            window.location.href = `${baseUri}/authentication/login?returnUrl=${encodeURI(`${baseUri}/store`)}`;
        }*/

        const token = await authService.getAccessToken();
        this.setState({
            token: token,
            isAuthenticated: authenticated,
            loading: false
        });
    }

    render() {
        return (
            <div className={"main-layout vh-100"}>
                <div className="hide-scrollbar h-100" id="profile-content" style={{ backgroundColor: '#495057' }}>
                    <div className="d-flex flex-column h-100">
                        <div className="hide-scrollbar">
                            {!this.state.loading &&
                                <Header
                                    isAuthenticated={this.state.isAuthenticated}
                                    token={this.state.token}
                                />
                            }
                            <div className="container-xl mt-1">
                                <div className="row">
                                    <div className="col" style={{ height: "500px" }}>
                                        <div className="card">
                                            <div className="d-flex flex-column align-items-center">
                                                <p>STORE COMING SOON</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Store;