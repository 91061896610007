import React, { Component } from "react";

import PencilSvg from "../../assets/media/heroicons/solid/pencil.svg";
import { Lock, Person } from '@material-ui/icons';
import { BitcoinCircleIcon, BitcoinIcon } from '@bitcoin-design/bitcoin-icons-react/filled';

import { Link } from "react-router-dom";
import { Accordion, Card, Button } from "react-bootstrap";
import Footer from "./Footer";

// Home page component
class Home extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className={"main-layout vh-100 card-bg-1"}>
                <div className="d-flex flex-column h-100 hide-scrollbar">
                    <div className="container d-flex flex-column">
                        <div className="row no-gutters text-center align-items-center justify-content-center">
                            <div className="col-12">
                                <div className="row mb-3">
                                    <div className="col-12 mb-2">
                                        <img src="/images/logo2.png" alt="Only Sats" className="img-fluid m-2" />
                                        <h1 className="m-2" style={{ fontSize: "18px" }}>Support your favorite creators with the Bitcoin network</h1>
                                    </div>
                                    <div className="col-md-5 offset-md-1">
                                        <a role="button" className="btn btn-lg btn-primary btn-block mb-2 text-uppercase font-weight-semibold" href="/authentication/login"><Lock style={{ fill: "#FFF" }} /> Login</a>
                                    </div>
                                    <div className="col-md-5">
                                        <a role="button" className="btn btn-lg btn-primary btn-block mb-2 text-uppercase font-weight-semibold" href="/identity/account/register?returnUrl=%2Faccount">
                                            <Person style={{ fill: "#FFF" }} /> Sign up</a>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <div className="mb-2">
                                            <Accordion defaultActiveKey="0" className="text-left text-white small">
                                                <Card>
                                                    <Card.Header style={{ cursor: "pointer" }}>
                                                        <Accordion.Toggle as={Card.Header} eventKey="0">
                                                            How is OnlySats different from other Fans sites?
                                                        </Accordion.Toggle>
                                                    </Card.Header>

                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body>
                                                            <p>
                                                                We use the Bitcoin Lightning Network to provide global, permissionless, low-fee and instant payments.
                                                                Sounds fancy but what does that mean?
                                                            </p>
                                                            <ol className="mt-1">
                                                                <li>Creators keep 100% of their money. We don't take 30% like other sites.</li>
                                                                <li>Payments are final and there are no refunds for any reason.</li>
                                                                <li>Your money is available immediately. No waiting for monthly payouts or minimum amounts.</li>
                                                                <li>As long as your content complies with U.S. laws, no bank or centralized financial institution
                                                                    can stop you from getting paid.</li>
                                                            </ol>
                                                            <p className="mt-1">
                                                                Lastly, and most importantly: we love you more. Be sure to watch our beautiful co-creator <a href="/p/wandererinutah">Angela </a>
                                                                explain <a href="/how-it-works">how OnlySats works</a>
                                                            </p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                                <Card>
                                                    <Card.Header style={{ cursor: "pointer" }}>
                                                        <Accordion.Toggle as={Card.Header} eventKey="1">
                                                            What are "sats"?
                                                        </Accordion.Toggle>
                                                    </Card.Header>

                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body>
                                                            <p>
                                                                Each bitcoin is divisible into 100,000,000 (one-hundred million) Satoshis. The term "sats" is shorthand for
                                                                "Satoshis". For example: 5,000 sats is equal to 0.00005000 BTC. Transacting in terms of sats rather than
                                                                fractions of bitcoin is more convenient and less error-prone for nearly all transactions.
                                                            </p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                                <Card>
                                                    <Card.Header style={{ cursor: "pointer" }}>
                                                        <Accordion.Toggle as={Card.Header} eventKey="2">
                                                            What if I don't want to be paid in Bitcoin (BTC)?
                                                        </Accordion.Toggle>
                                                    </Card.Header>

                                                    <Accordion.Collapse eventKey="2">
                                                        <Card.Body>
                                                            <p>
                                                                We hope you change your mind but if you prefer to receive payment in Fiat (USD, EUR, etc.), OnlySats is still for you:
                                                            </p>
                                                            <p>
                                                                This version of OnlySats uses a custodial Bitcoin payment processor that is capable of providing the
                                                                exchange at time of settlement so that you can leverage the power of the Bitcoin network but never
                                                                have exposure to BTC as a currency. In other words, once your account is setup, you can choose to automatically
                                                                settle all received payments in BTC, Fiat (USD, EUR, etc.) or a percentage of each.
                                                            </p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                                <Card>
                                                    <Card.Header style={{ cursor: "pointer" }}>
                                                        <Accordion.Toggle as={Card.Header} variant="link" eventKey="3">
                                                            Can I be paid in a cryptocurrency other than BTC?
                                                        </Accordion.Toggle>
                                                    </Card.Header>

                                                    <Accordion.Collapse eventKey="3">
                                                        <Card.Body>
                                                            No.
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
export default Home;