import React, { Component } from "react";
import ChatFindContact from "./ChatFindContact";
import ChatContacts from "./ChatContacts";

import "./ChatSidebar.css";

// Container for contact list and search
class ChatSidebar extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="sidebar-main" className="hide-scrollbar">
                {false &&
                    <div className="top-left-search">
                        <ChatFindContact
                            changeFindContact={this.props.changeFindContact}
                            findContact={this.props.findContact}
                        />
                    </div>
                }

                <ChatContacts
                    chatSessions={this.props.chatSessions}
                    findContact={this.props.findContact}
                    activeContact={this.props.activeContact}
                    clickActiveChatSession={this.props.clickActiveChatSession}
                    changeFindContact={this.props.changeFindContact}
                    loadingChatSessions={this.props.loadingChatSessions}
                />
            </div>
        );
    }
}

export default ChatSidebar;