import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

class MessagePrice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPrice: 0
        }
    }

    componentDidMount() {
        this.setState({
            currentPrice: this.props.messagePrice
        });
    }

    setModalShow = () => {
        this.props.hideMessagePrice();
    };

    setCurrentPrice = (e) => {
        this.setState({
            currentPrice: e.target.value
        });
    }

    updateMessagePrice = () => {
        this.props.setMessagePrice(this.state.currentPrice);
        this.props.hideMessagePrice();
    }

    render() {
        return (
            <Modal
                show={true}
                scrollable={true}
                onHide={() => this.setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Set your price</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0 hide-scrollbar">
                    <div
                        className="input-group m-3 d-flex justify-content-center"
                        style={{ width: "50%" }}
                    >
                        <input
                            type="text"
                            className="form-control form-control-md"
                            value={this.state.currentPrice}
                            onChange={this.setCurrentPrice}
                        />
                        <div className="input-group-append">
                            <span className="input-group-text">sats</span>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center mt-2 mb-2">
                        <button
                            type="button"
                            className="btn btn-outline-danger mx-1"
                            onClick={() => this.setModalShow(false)}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary mx-1"
                            onClick={this.updateMessagePrice}
                        >
                            Update
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default MessagePrice;