import React, { Component } from "react";
import { Link } from 'react-router-dom';
import "./ChatContact.css";

// Component for a single Contact
class ChatContact extends Component {

    constructor(props) {
        super(props);
        //console.log("CONTACT", props);
    }

    render() {
        return (
            <li className={this.props.chatSessionId == this.props.id ? "contacts-item friends active" : "contacts-item friends"}>
                <Link
                    className="contacts-link"
                    to="#"
                    onClick={() => this.props.clickActiveChatSession(this.props.id)}
                >
                    <div className={"avatar avatar-online"}>
                        <img src={this.props.contactAvatar} alt=""></img>
                    </div>
                    <div className="contacts-content">
                        <div className="contacts-info">
                            <h6 className="chat-name text-truncate">{this.props.contactName}</h6>
                            <div className="chat-time">{this.props.time}</div>
                        </div>
                        <div className="contacts-texts">
                            <p className="text-truncate">{this.props.lastMessage}</p>
                        </div>
                    </div>
                </Link>
            </li>
        );
    }
}

export default ChatContact;