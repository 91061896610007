import React, { Component } from "react";
import axios from "axios";

class SecuritySettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            showActivityStatus: false,
            fullyPrivateProfile: false,
            showPatronCount: false,
            showMediaCount: false,
            watermarkPhotos: false,
            watermarkVideos: false,
            saving: false
        };
    }

    async componentDidMount() {

        const response = await fetch(`api/account/c/${this.props.creatorId}/security_settings`, {
            headers: { 'Authorization': `Bearer ${this.props.token}` }
        });

        if (response.status != 200) {
            alert("DO SOMETHING. Couldn't grab security settings");
        }

        const securitySettings = await response.json();

        this.setState({
            loading: false,
            showActivityStatus: securitySettings.showActivityStatus,
            fullyPrivateProfile: securitySettings.fullyPrivateProfile,
            showPatronCount: securitySettings.showPatronCount,
            showMediaCount: securitySettings.showMediaCount,
            watermarkPhotos: securitySettings.watermarkPhotos,
            watermarkVideos: securitySettings.watermarkVideos
        });
    }

    saveForm = async () => {
        this.setState({
            saving: true
        });

        const formData = new FormData();

        formData.append("showActivityStatus", this.state.showActivityStatus);
        formData.append("showMediaCount", this.state.showMediaCount);
        formData.append("showPatronCount", this.state.showPatronCount);
        formData.append("watermarkPhotos", this.state.watermarkPhotos);
        formData.append("watermarkVideos", this.state.watermarkVideos);
        formData.append("fullyPrivateProfile", this.state.fullyPrivateProfile);

        const response = await axios.post(`/api/account/c/${this.props.creatorId}/security_settings`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + this.props.token
            }
        });

        if (response.status != 200) {
            alert("DO SOMETHING. Couldn't set security settings");
        }

        this.setState({
            saving: false
        });
    }

    render() {
        return (
            <>
                {this.state.loading &&
                    <p className="text-white">Loading...</p>
                }
                {!this.state.loading &&
                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="back-to-contact" onClick={this.props.handleCloseDetails} >
                                <span className="material-icons" style={{ color: "#495057" }}>arrow_back_ios</span>
                                <span className="text-white ml-2">Security Settings</span>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="text-right sticky-top" style={{ padding: 0, margin: 0 }}>
                                {
                                    !this.state.saving &&
                                    <span className="material-icons text-primary" style={{ cursor: "pointer" }} onClick={this.saveForm}>save_as</span>
                                }
                                {
                                    this.state.saving &&
                                    <span className="text-white">Saving...</span>
                                }
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" id="showActivityStatus" checked={this.state.showActivityStatus} onChange={(e) => {
                                                this.setState({ showActivityStatus: !this.state.showActivityStatus })
                                            }} />
                                            <label className="custom-control-label" htmlFor="showActivityStatus">Show Activity Status</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" id="fullyPrivateProfile" checked={this.state.fullyPrivateProfile} onChange={(e) => {
                                                this.setState({ fullyPrivateProfile: !this.state.fullyPrivateProfile })
                                            }} />
                                            <label className="custom-control-label" htmlFor="fullyPrivateProfile">Fully Private Profile</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" id="showPatronCount" checked={this.state.showPatronCount} onChange={(e) => {
                                                this.setState({ showPatronCount: !this.state.showPatronCount })
                                            }} />
                                            <label className="custom-control-label" htmlFor="showPatronCount">Show Fan Count</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" id="showMediaCount" checked={this.state.showMediaCount} onChange={(e) => {
                                                this.setState({ showMediaCount: !this.state.showMediaCount })
                                            }} />
                                            <label className="custom-control-label" htmlFor="showMediaCount">Show Media Count</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" id="watermarkPhotos" checked={this.state.watermarkPhotos} onChange={(e) => {
                                                this.setState({ watermarkPhotos: !this.state.watermarkPhotos })
                                            }} />
                                            <label className="custom-control-label" htmlFor="watermarkPhotos">Watermark Photos</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" id="watermarkVideos" checked={this.state.watermarkVideos} onChange={(e) => {
                                                this.setState({ watermarkVideos: !this.state.watermarkVideos })
                                            }} />
                                            <label className="custom-control-label" htmlFor="watermarkVideos">Watermark Videos</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default SecuritySettings;