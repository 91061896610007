// Will be used for Adding and Editing Creator subscription packages
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

class SubscriptionPackageDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            name: '',
            cost: 0,
            numDays: 0,
            isActive: true,
            saving: false,
        };
    }

    componentDidMount() {
        if (this.props.subscriptionPackage !== null) {
            this.setState({
                id: this.props.subscriptionPackage.subscriptionPackageId,
                name: this.props.subscriptionPackage.displayName,
                cost: this.props.subscriptionPackage.costSatoshis,
                numDays: this.props.subscriptionPackage.numDays,
                isActive: this.props.subscriptionPackage.isActive
            });
        }
    }

    setModalShow = () => {
        this.props.hideSubscriptionPackageDetails();
    };

    updateSubscriptionPackage = async () => {
        this.setState({
            saving: true
        });

        const formData = new FormData();
        formData.append("id", this.state.id);
        formData.append("name", this.state.name);
        formData.append("cost", this.state.cost);
        formData.append("numDays", this.state.numDays);
        formData.append("isActive", this.state.isActive);

        const response = await axios.post(`/api/account/c/${this.props.creatorId}/subscription_packages`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + this.props.token
            }
        });

        if (response.status != 200) {
            alert("DO SOMETHING. Couldn't update subscription package");
        }

        const subscriptionPackage = response.data;


        this.props.setSubscriptionPackageDetails({
            subscriptionPackageId: subscriptionPackage.id,
            displayName: subscriptionPackage.displayName,
            costSatoshis: subscriptionPackage.costSatoshis,
            numDays: subscriptionPackage.numDays,
            isActive: subscriptionPackage.isActive
        });

        this.setState({
            saving: false
        });

        this.props.hideSubscriptionPackageDetails();
    }

    setCost = (e) => {
        this.setState({
            cost: parseInt(e.target.value)
        });
    }

    setName = (e) => {
        this.setState({
            name: e.target.value
        });
    }

    setNumDays = (e) => {
        this.setState({
            numDays: parseInt(e.target.value)
        });
    }

    render() {
        return (
            <Modal
                show={true}
                scrollable={true}
                onHide={() => this.setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Subscription Package Details</h5>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0 hide-scrollbar">
                    <div className="row mx-2 mt-1">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="displayName">Display name</label>
                                <div
                                    className="d-flex justify-content-start"
                                >
                                    <input
                                        type="text"
                                        className="form-control form-control-md"
                                        value={this.state.name}
                                        id="displayName"
                                        placeholder="Display name"
                                        onChange={this.setName}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="cost">Cost (satoshis)</label>
                                <div
                                    className="d-flex justify-content-start"
                                >
                                    <input
                                        type="text"
                                        className="form-control form-control-md"
                                        value={this.state.cost}
                                        id="cost"
                                        placeholder="Cost"
                                        onChange={this.setCost}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="numDays"># of days</label>
                                <div
                                    className="d-flex justify-content-start"
                                >
                                    <input
                                        type="text"
                                        className="form-control form-control-md"
                                        value={this.state.numDays}
                                        id="numDays"
                                        placeholder="# of days"
                                        onChange={this.setNumDays}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-right">
                            <div className="form-group">

                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="packageActive" checked={this.state.isActive} onChange={(e) => {
                                        this.setState({ isActive: !this.state.isActive })
                                    }} />
                                    <label className="custom-control-label" htmlFor="packageActive">Active</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-2 mb-2">

                        {
                            !this.state.saving &&
                            <>
                                <button
                                    type="button"
                                    className="btn btn-outline-danger mx-1"
                                    onClick={() => this.setModalShow(false)}
                                >
                                    Cancel
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-primary mx-1"
                                    onClick={this.updateSubscriptionPackage}
                                >
                                    Update
                                </button>
                            </>
                        }
                        {
                            this.state.saving &&
                            <span className="text-white">Saving...</span>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default SubscriptionPackageDetails;