import React, { Component } from "react";
import { ControlPoint, Mood, Attachment } from '@material-ui/icons';
import { Dropdown, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import SendArrow from "../../../assets/media/heroicons/outline/arrow-right.svg";
import { ReactComponent as GallarySvg } from "../../../assets/media/icons/gallary.svg";
import { ReactComponent as AudioSvg } from "../../../assets/media/icons/audio.svg";
import Picker from "emoji-picker-react";
import ChatMedia from "../../Modals/ChatMedia/ChatMedia";

class CreateFeedPost extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showChatMedia: false
        };
    }

    async componentDidMount() {

    }

    keyDownHandler = async (e) => {
        if (e.keyCode === 13 && !this.props.creatingFeedPost) {
            await this.props.onCreateNewPost();
        }
    }

    toggleChatMedia = () => {
        this.setState({
            showChatMedia: !this.state.showChatMedia,
        });
    };

    setMediaMessage = (media) => {
        this.props.setAttachedMedia(media);
    }

    handleClearFiles = () => {
        if (confirm("Clear attachments from message?")) {
            this.props.setAttachedMedia([]);
        }
    }

    render() {
        return (
            <>
                <div className="bottom-chat-content mt-1">
                    <h5>Create Post</h5>
                    <div className="input-group" >
                        {
                            this.props.attachedMedia && this.props.attachedMedia.length > 0 &&
                            <div className="input-group-prepend">
                                <span className="input-group-text"
                                    onClick={this.handleClearFiles}
                                    style={{ cursor: "pointer" }}
                                ><Attachment /></span>
                            </div>
                        }
                        <textarea
                            className="form-control form-control-md"
                            placeholder="Write a message..."
                            value={this.props.messageInput}
                            onChange={this.props.changeMessageInput}
                            onKeyUp={async (e) => await this.keyDownHandler(e)}
                            rows="4"
                        ></textarea>
                    </div>

                    <div className="input-interactive mt-2" style={{ justifyContent: "end" }} >
                        <Dropdown>
                            <Dropdown.Toggle
                                as={ControlPoint}
                                style={{ fill: "#FFF" }}
                            ></Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu">
                                <Link
                                    className="dropdown-item"
                                    to="#"
                                    title="Media"
                                    data-toggle="modal"
                                    data-target="#chatMediaModal"
                                    onClick={this.toggleChatMedia}>
                                    <GallarySvg className="hw-20 mr-2" />
                                    <span>Media</span>

                                </Link>
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className="text-right">
                            <div className="send" >
                                {
                                    !this.props.creatingFeedPost ?
                                        <div
                                            className="btn btn-primary btn-icon rounded-circle mb-1"
                                            role="button"
                                            onClick={async () => await this.props.onCreateNewPost()}
                                        >
                                            <img src={SendArrow} alt="" className="injectable hw-24" />
                                        </div> :
                                        <Spinner animation="border" variant="primary" className="mb-1" />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.state.showChatMedia &&
                    <ChatMedia
                        hideChatMedia={this.toggleChatMedia}
                        setMediaMessage={this.setMediaMessage}
                    />
                }
            </>
        );
    }
}

export default CreateFeedPost;