import React, { Component } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { ReactComponent as SearchSvg } from "../../assets/media/icons/search.svg";

import "./ChatFindContact.css";

class ChatFindContact extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (

            <div className="sidebar-sub-header" style={{ padding: "10px 10px" }}>
                <DropdownButton
                    className="border-0 f-16 p-0 min-w-150 cut-texts"
                    style={{ width: "100%" }}
                    variant="outline-default"
                    onSelect={() => { alert('Select changed'); }}
                    title={"Contact Lists"}
                >
                    <Dropdown.Item
                        eventKey={1}
                        active={true}
                    >
                        All Chats
                    </Dropdown.Item>
                    <Dropdown.Item
                        eventKey={2}
                        active={false}
                    >
                        Friends
                    </Dropdown.Item>
                </DropdownButton>

                <form className="form-inline">
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control search border-right-0 transparent-bg pr-0"
                            placeholder="Search..."
                            value={this.props.findContact}
                            onChange={this.props.changeFindContact}
                        ></input>
                        <div className="input-group-append">
                            <div
                                className="input-group-text transparent-bg border-left-0"
                                role="button"
                            >
                                <SearchSvg className="text-muted hw-20" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default ChatFindContact;