import React, { Component } from "react";
import { ControlPoint, Mood, MoreVert, Photo, Send, Attachment, Clear } from '@material-ui/icons';
import { Dropdown, Alert, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import SendArrow from "../../assets/media/heroicons/outline/arrow-right.svg";
import { BitcoinCircleIcon, BitcoinIcon } from '@bitcoin-design/bitcoin-icons-react/filled';
import { ReactComponent as ChatPlusSvg } from "../../assets/media/icons/chatplus.svg";
import { ReactComponent as GallarySvg } from "../../assets/media/icons/gallary.svg";
import { ReactComponent as AudioSvg } from "../../assets/media/icons/audio.svg";
import { ReactComponent as ContactSvg } from "../../assets/media/icons/contact.svg";
import ChatMessage from "./ChatMessage";
import ChatPaywall from "./ChatPaywall";
import ChatMedia from "../Modals/ChatMedia/ChatMedia";
import TipCreator from "../Modals/TipCreator/TipCreator";
import MessagePrice from "../Modals/MessagePrice/MessagePrice";
import Picker from "emoji-picker-react";
import "./ChatDetail.css";

// The actual chat window with the selected contact
class ChatDetail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showOptions: false,
            openSettingsChat: false,
            openInfoChat: false,
            showChatMedia: false,
            showMessagePrice: false,
            showTip: false,
            isCreator: props.claims.UserRole[0].toLowerCase() === "creator",
            creatorId: props.creatorId
        };
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    getAvatar = () => {
        const chatSession = this.props.chatSessions.find(c => c.id === this.props.chatSessionId);
        if (chatSession) {
            return chatSession.contactAvatar;
        }

        return '';
    }

    toggleChatMedia = () => {
        this.setState({
            showChatMedia: !this.state.showChatMedia,
        });
    };

    toggleMessagePrice = () => {
        this.setState({
            showMessagePrice: !this.state.showMessagePrice
        });
    }

    toggleTip = () => {
        this.setState({
            showTip: !this.state.showTip
        })
    }

    setMediaMessage = (media) => {
        this.props.setAttachedMedia(media);
    }

    setMessagePrice = (price) => {
        this.props.setMessagePrice(price);
        //console.log("Message price set to: " + price);
    }

    handleSettingsChat = () => {
        this.setOpenSettingsChat(true);
        this.handleMore("shutdown");
    }

    handleInfoChat = () => {
        this.setOpenInfoChat(true);
        this.handleMore("shutdown");
    }

    handleCloseChat = () => {
        this.props.setActiveChatSession(0);
        this.props.setOpenChat(false);

        document.getElementById("center-bar").style.display = "none"
    }

    handleClearFiles = () => {
        if (confirm("Clear attachments from message?")) {
            this.props.setAttachedMedia([]);
        }
    }

    handleClearPrice = () => {
        if (confirm("Clear price from message?")) {
            this.props.setMessagePrice(0);
        }
    }

    setOpenSettingsChat = (val) => {
        this.setState({ openSettingsChat: val });
    }

    setOpenInfoChat = (val) => {
        this.setState({ openInfoChat: val });
    }

    handleMore = (role) => {
        if (role === "toggle") {
            this.setState({ showOptions: !this.state.showOptions });
        } else if (role === "shutdown") {
            this.setState({ showOptions: false });
        }
    }

    keyDownHandler = async (e) => {
        if (e.keyCode === 13 && !this.props.sendingMessage) {
            await this.props.onSend();
        }
    }

    scrollToBottom = () => {
        if (this.messagesEnd) {
            this.messagesEnd.scrollIntoView({ behavior: "smooth" });
        }
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    render() {
        return (
            <>
                <div id="chat-detail">
                    {
                        this.state.showOptions &&

                        <div className="more-chat" >
                            <div onClick={this.handleSettingsChat} className="content-more-chat" >
                                <span className="material-icons">settings</span>
                                <h4>Settings</h4>
                            </div>
                            <div onClick={this.handleInfoChat} className="content-more-chat" >
                                <span className="material-icons">info</span>
                                <h4>Information</h4>
                            </div>
                        </div>
                    }
                    <div className="top-chat-content" >
                        {
                            !this.state.openSettingsChat && !this.state.openInfoChat &&
                            <>
                                {
                                    this.props.chatSessionId > 0 &&
                                    <div className="left-top-chat-content" >
                                        <div className="back-to-contact" onClick={this.handleCloseChat} >
                                            <span className="material-icons" style={{ color: "#FFF" }}>arrow_back_ios</span>
                                        </div>
                                        <div className="profile-chat"  >
                                            <img alt="" src={this.getAvatar()} />
                                            <span className="text-white ml-2">{this.props.theirUsername}</span>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                    {
                        this.props.showPaywall &&
                        <div className="center-chat-content">
                            <ChatPaywall
                                bolt11={this.props.bolt11}
                                bolt11ExpiresAt={this.props.bolt11ExpiresAt}
                                houseFeeSats={this.props.houseFeeSats}
                                chatSessionId={this.props.chatSessionId}
                                clickActiveChatSession={this.props.clickActiveChatSession}
                                theirUsername={this.props.theirUsername}
                                claims={this.props.claims}
                            />
                        </div>
                    }
                    {
                        !this.props.showPaywall &&
                        !this.state.openSettingsChat &&
                        !this.state.openInfoChat &&

                        <div onClick={() => { this.handleMore("shutdown") }} className="center-chat-content" >
                            <div className="chat-messages-container hide-scrollbar">
                                {
                                    !this.props.formattingMessages &&
                                    this.props.messages.length > 0 &&
                                    this.props.messages.map((message) => (
                                        <ChatMessage
                                            key={message.eventId}
                                            {...message}
                                            chatSessionId={this.props.chatSessionId}
                                            token={this.props.token}
                                            scrollToBottom={this.scrollToBottom}
                                            allowPayWithAlby={this.props.allowPayWithAlby}
                                        />
                                    ))
                                }
                                {
                                    this.props.formattingMessages &&
                                    <p className="text-white text-center">
                                        Decrypting chat history...
                                    </p>
                                }
                                <div id="messagesEndMarker" style={{ float: "left", clear: "both" }}
                                    ref={(el) => { this.messagesEnd = el; }}>
                                </div>
                            </div>
                            {
                                this.props.openEmojis &&
                                <div className="emoji-x" >
                                    <Picker
                                        onEmojiClick={this.props.onEmojiClick}
                                        disableSkinTonePicker={true}
                                    />
                                </div>
                            }
                        </div>
                    }

                    {
                        !this.props.showPaywall &&
                        !this.state.openSettingsChat &&
                        !this.state.openInfoChat &&
                        <div className="bottom-chat-content">
                            <div onClick={() => { this.handleMore("shutdown") }} id="bottom-bar-chat" >

                                <div className="input-group" >
                                    {
                                        this.props.attachedMedia && this.props.attachedMedia.length > 0 &&
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"
                                                onClick={this.handleClearFiles}
                                                style={{ cursor: "pointer" }}
                                            ><Attachment /></span>
                                        </div>
                                    }
                                    {
                                        <input type="text"
                                            className="form-control form-control-md"
                                            placeholder="Write a message..."
                                            value={this.props.messageInput}
                                            onChange={this.props.changeMessageInput}
                                            onKeyUp={async (e) => await this.keyDownHandler(e)}
                                        />
                                    }
                                    {
                                        this.props.messagePrice > 0 &&
                                        <div className="input-group-append">
                                            <span className="input-group-text"
                                                onClick={this.handleClearPrice}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <BitcoinIcon className={"hw-20"} />
                                            </span>
                                        </div>
                                    }
                                    <div className="input-interactive" >
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                as={ControlPoint}
                                                style={{ fill: "#FFF", marginBottom: "2px" }}
                                            ></Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu">
                                                {
                                                    this.state.isCreator &&
                                                    <>
                                                        <Link
                                                            className="dropdown-item"
                                                            to="#"
                                                            title="Media"
                                                            data-toggle="modal"
                                                            data-target="#chatMediaModal"
                                                            onClick={this.toggleChatMedia}>
                                                            <GallarySvg className="hw-20 mr-2" />
                                                            <span>Media</span>

                                                        </Link>
                                                        <Link className="dropdown-item" to="#">
                                                            <AudioSvg />
                                                            <span>Voice Message</span>
                                                        </Link>
                                                        <Link
                                                            className="dropdown-item"
                                                            to="#"
                                                            title="Set Message Price"
                                                            data-toggle="modal"
                                                            data-target="#messagePriceModal"
                                                            onClick={this.toggleMessagePrice}>
                                                            <BitcoinIcon className={"hw-20 mr-2"} />
                                                            <span>Set Price</span>
                                                        </Link>
                                                    </>
                                                }
                                                {
                                                    !this.state.isCreator &&
                                                    <>
                                                        <Link
                                                            className="dropdown-item"
                                                            to="#"
                                                            title="Tip"
                                                            data-toggle="modal"
                                                            data-target="#tipModal"
                                                            onClick={this.toggleTip}>
                                                            <BitcoinIcon className={"hw-20 mr-2"} />
                                                            <span>Tip Creator</span>
                                                        </Link>
                                                    </>
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Mood onClick={this.props.handleOpenEmojis} style={{ fill: "#FFF" }} />
                                    </div>
                                </div>
                                <div className="send" >
                                    {
                                        !this.props.sendingMessage ?
                                            <div
                                                className="btn btn-primary btn-icon rounded-circle mb-1"
                                                role="button"
                                                onClick={async () => await this.props.onSend()}
                                            >
                                                <img src={SendArrow} alt="" className="injectable hw-24" />
                                            </div> :
                                            <Spinner animation="border" variant="primary" className="mb-1" />
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.openSettingsChat &&
                        <div>
                            <p>Chat settings</p>
                        </div>
                    }
                    {
                        this.state.openInfoChat &&
                        <div className="info-chat">
                            <p>Chat info</p>
                        </div>
                    }
                </div>

                {
                    this.state.showChatMedia &&
                    <ChatMedia
                        hideChatMedia={this.toggleChatMedia}
                        setMediaMessage={this.setMediaMessage}
                    />
                }
                {
                    this.state.showMessagePrice &&
                    <MessagePrice
                        hideMessagePrice={this.toggleMessagePrice}
                        setMessagePrice={this.setMessagePrice}
                        messagePrice={this.props.messagePrice}
                    />
                }
                {
                    this.state.showTip &&

                    <TipCreator
                        hideTipCreator={this.toggleTip}
                        creatorUsername={this.props.theirUsername}
                        token={this.props.token}
                        creatorId={this.state.creatorId}
                        suggestedTip={25000}
                    />
                }
            </>
        );
    }
}

export default ChatDetail;