import React, { Component } from "react";
import authService from '../api-authorization/AuthorizeService';
import queryString from "query-string";

class AlbyCallback extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetchingToken: true
        }
    }

    async componentDidMount() {
        const authenticated = await authService.isAuthenticated();

        if (!authenticated) {
            const baseUri = window.location.origin;
            window.location.href = `${baseUri}/authentication/login?returnUrl=${encodeURI(`${baseUri}/portal`)}`;
        }

        const token = await authService.getAccessToken();
        const params = queryString.parse(this.props.location.search);
        const response = await fetch(`api/webhooks/auth_alby?code=${params.code}`, {
            headers: { 'Authorization': `Bearer ${token}` }
        });

        const baseUri = window.location.origin;
        if (response.status != 200) {
            window.location.href = `${baseUri}/portal?error=COULD_NOT_RETRIEVE_ALBY_TOKEN`;
        }
        else {
            window.location.href = `${baseUri}/portal`;
        }
    }

    render() {
        return (
            this.state.fetchingToken &&
            <p>Fetching token...</p>
        );
    }
}

export default AlbyCallback;