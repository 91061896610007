import React, { Component } from "react";

import { QRCodeCanvas } from "qrcode.react";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";

import "./FeedPost.css";

// A single Feed Post. Takes in a ChatMessage data object but displays differently
class FeedPost extends Component {

    constructor(props) {
        super(props);

        const messageContent = JSON.parse(props.messageContentJson);
        const images = messageContent.Media.filter(s => s.MimeType.startsWith('image/'));
        const videos = messageContent.Media.filter(s => s.MimeType.startsWith('video/'));

        this.state = {
            messageContent: messageContent,
            images: images,
            videos: videos
        };
    }

    componentDidMount() {
        //console.log(this.state.messageContent);
    }

    render() {
        return (
            <li
                className="contacts-item friends mb-3"
                style={
                    this.props.showBorder ?
                        { borderBottom: "0.5px solid #343a40" } :
                        {}
                }>

                <div className={"avatar avatar-online"}>
                    <img src={this.props.senderAvatarUrl} alt="" />
                </div>
                <div className="contacts-content">
                    <div className="contacts-info">
                        <h6 className="chat-name text-truncate">{this.props.displayName}<br />@{this.props.senderUsername}</h6>
                        <div className="chat-time">
                            {this.props.dateReceived}</div>
                    </div>
                    <div className="contacts-texts" style={{ marginTop: "2rem" }}>
                        <div className="text-white" dangerouslySetInnerHTML={{ __html: this.state.messageContent.MessageText }}></div>
                    </div>
                    {
                        this.state.messageContent.Type === 2 &&
                        this.state.messageContent.Media &&
                        this.state.messageContent.Media.length > 0 &&
                        <>
                            {
                                this.state.images &&
                                this.state.images.length > 0 &&
                                <div className="form-row mt-1">
                                    <LightgalleryProvider>
                                        {this.state.images.map((media) => {
                                            return <div className="col" key={media.MediaAssetId}>
                                                <LightgalleryItem
                                                    group={"group1"}
                                                    src={media.RemoteLocation}
                                                    thumb={media.RemoteLocation}
                                                >
                                                    <img
                                                        alt="photo1"
                                                        src={media.RemoteLocation}
                                                        style={{ width: "100%" }}
                                                    />
                                                </LightgalleryItem>
                                            </div>;
                                        })}
                                    </LightgalleryProvider>
                                </div>
                            }
                            {this.state.videos &&
                                this.state.videos.length > 0 &&
                                this.state.videos.map((media) => {
                                    return <div className="form-row mt-1" key={media.MediaAssetId}>
                                        <video width="100%" controls>
                                            <source src={media.RemoteLocation} type={media.MimeType} />
                                        </video>
                                    </div>;
                                })}
                        </>
                    }
                    <p style={{ marginBottom: "2rem" }}></p>
                </div>
                <div className="feed-post-options">

                </div>
            </li >
        );
    }
}

export default FeedPost;