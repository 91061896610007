import React, { Component } from "react";
import FeedPost from './FeedPost';

import { ReactComponent as StarSvg } from "../../../assets/media/heroicons/outline/star.svg"
class Feed extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    async componentDidMount() {

    }

    render() {
        return (
            <>
                {
                    this.props.isPatron &&
                    !this.props.isSubscribed &&
                    <div className="d-flex align-items-center flex-column justify-content-center">
                        <button type="button"
                            className="btn btn-primary btn-block mb-2 mt-2 text-uppercase font-weight-semibold"
                            onClick={this.props.showSubscribeCreator}
                        >
                            <StarSvg /> Subscribe to see posts
                        </button>
                    </div>
                }
                {
                    (this.props.isOwner || this.props.isSubscribed) &&
                    <div className="creator-feed text-left mt-2">
                        {
                            !this.props.loading &&
                            this.props.feed.length > 0 &&
                            <ul className="contacts-list">
                                {
                                    this.props.feed.map((feedPost, index) => {
                                        const showBorder = index < this.props.feed.length - 1;

                                        return (
                                            <FeedPost
                                                key={feedPost.eventId}
                                                {...feedPost}
                                                displayName={this.props.displayName}
                                                token={this.props.token}
                                                showBorder={showBorder}
                                            />
                                        );
                                    })
                                }
                            </ul>
                        }
                        <div className="text-center mb-2 ">
                            <button className="btn btn-sm btn-primary">Load More</button>
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default Feed;