import React, { Component } from "react";
import Footer from "./Footer";


class About extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <div className={"main-layout vh-100 card-bg-1"}>
                <div className="d-flex flex-column h-100 hide-scrollbar">
                    <div className="container d-flex flex-column">
                        <div className="row no-gutters text-center align-items-center justify-content-center">
                            <div className="col-12">
                                <div className="row mb-3">
                                    <div className="col-12 mb-2">
                                        <img src="/images/logo2.png" alt="Only Sats" className="img-fluid m-2" />
                                        <h1 className="m-2" style={{ fontSize: "18px" }}>Why build yet another Fans site?</h1>
                                    </div>
                                    <div className="col-md-8 offset-md-2 text-white text-left">
                                        <p>We are content creators and we've used the other Fans sites. We saw some glaring problems that we knew we could fix:</p>
                                        <ol>
                                            <li>Fees. Why are you taking 30% of my money?</li>
                                            <li>Payments. Why do I need to wait a month for my money to be available?</li>
                                            <li>Fraud. Why should I be penalized for someone else's fraud and my money deducted from my account after I've already shared my content?</li>
                                            <li>Peace of mind. Why am I at the mercy of what Visa or Mastercard deem appropriate? Centralized financial institutions can shut down my income stream at any time.</li>
                                        </ol>
                                        <p>
                                            Luckily, <strong>Bitcoin fixes this</strong>. The solution was obvious to us: build tooling that relied on the Bitcoin Lightning Network to transfer content from one party
                                            to another.
                                        </p>
                                        <p>
                                            We're just getting started at OnlySats and we'll be rapidly iterating on tooling to help content creators be paid fairly and instantly on their terms.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default About;