import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import { ReactComponent as AddContactSvg } from "../../../assets/media/icons/addcontact.svg";
import { ReactComponent as CheckCircleSvg } from "../../../assets/media/heroicons/outline/check-circle.svg";
import { ReactComponent as PhotographSvg } from "../../../assets/media/heroicons/outline/photograph.svg";
import { Link } from "react-router-dom";
import axios from "axios";

// Notification list component
class Notifications extends Component {
    setModalShow = () => {
        this.props.hideNotification();
    };

    async componentDidMount() {

        await axios.post('/api/account/notifications', null, {
            headers: {
                "Authorization": "Bearer " + this.props.token
            }
        });
    }

    render() {
        return (
            <Modal
                show={true}
                scrollable={true}
                onHide={() => this.setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Notifications</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0 hide-scrollbar">
                    <div className="row">
                        <div className="col-12">
                            <ul className="list-group list-group-flush  py-2">
                                {this.props.notifications.map((notification, index) => (
                                    <li className="list-group-item" key={index}>
                                        <div className="media">
                                            <div className="btn btn-primary btn-icon rounded-circle text-light mr-2">
                                                <CheckCircleSvg />
                                            </div>

                                            <div className="media-body">
                                                <h6>
                                                    {notification.text}
                                                </h6>
                                                {notification.deeplink &&
                                                    notification.deeplink.length > 0 &&
                                                    <a href={notification.deeplink}>More...</a>
                                                }
                                            </div>
                                        </div>
                                    </li>
                                ))}
                                {
                                    this.props.notifications.length === 0 &&
                                    <li className="list-group-item">
                                        <div className="media">
                                            <div className="media-body">
                                                <h6>
                                                    No unread notifications
                                                </h6>
                                            </div>
                                        </div>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="link"
                        className="text-muted"
                        onClick={() => this.setModalShow(false)}
                    >
                        Clear all
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default Notifications;
