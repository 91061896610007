import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Dropdown } from "react-bootstrap";
import Switch from "react-switch";
import Header from "../../NavBar/Header";
import NavBar from "../../NavBar/NavBar";
import authService from '../../api-authorization/AuthorizeService';
import { ArrowForward } from '@material-ui/icons';


import SettingsStart from "./SettingsStart";
import ProfileSettings from "./ProfileSettings";
import ProfileSamples from "./ProfileSamples";
import StoreInventory from "./StoreInventory";
import SubscriptionPackages from "./SubscriptionPackages";
import ChatSettings from "./ChatSettings";
import NotificationSettings from "./NotificationSettings";
import SecuritySettings from "./SecuritySettings";
import FanSettings from "./FanSettings";

import { ReactComponent as BackToList } from "../../../assets/media/icons/backtolist.svg";
import "./Settings.css";


/* Manage account and profile */
class Settings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            token: '',
            username: '',
            creatorId: 0,
            userAccountId: 0,
            isCreator: false,
            loading: true,
            /* Flags for determining which(if any) windows to open */
            anyOptionSelected: false,
            openChatSettings: false,
            openNotificationSettings: false,
            openProfileSamples: false,
            openProfileSettings: false,
            openSecuritySettings: false,
            openStoreInventory: false,
            openSubscriptionPackages: false,
            openFanSettings: false,
            detailScreenTitle: '',
            optionsList: null,
        };
    }

    redirectToLogin = () => {
        const baseUri = window.location.origin;
        window.location.href = `${baseUri}/authentication/login?returnUrl=${encodeURI(`${baseUri}/account`)}`;
    }

    getCreatorOptions = () => {
        return [
            {
                id: 1,
                val: 'Account Settings'
            },
            {
                id: 2,
                val: 'Profile Samples'
            },
            {
                id: 3,
                val: 'Subscription Packages'
            },
            {
                id: 4,
                val: 'Store Inventory'
            },/*
                {
                    id: 5,
                    val: 'Chat Settings'
                },*/
            {
                id: 6,
                val: 'Notification Settings'
            },
            {
                id: 7,
                val: 'Security Settings'
            }
        ];
    }

    getPatronOptions = () => {
        return [
            {
                id: 100,
                val: 'Fan Settings'
            }
        ];
    }

    async componentDidMount() {

        const authenticated = await authService.isAuthenticated();

        if (!authenticated) {
            this.redirectToLogin();
        }

        const token = await authService.getAccessToken();

        const response = await fetch('api/claims', {
            headers: { 'Authorization': `Bearer ${token}` }
        });

        if (response.status != 200) {
            this.redirectToLogin();
        }

        const claims = await response.json();

        if (claims.UserRole[0].toLowerCase() === 'creator') {
            this.setState({
                creatorId: parseInt(claims.CreatorId[0]),
                isCreator: true,
                optionsList: this.getCreatorOptions()
            });

        } else {
            this.setState({
                optionsList: this.getPatronOptions()
            });
        }

        this.setState({
            isAuthenticated: authenticated,
            token: token,
            loading: false,
            username: claims.Username[0],
            userAccountId: parseInt(claims.UserAccountId[0])
        });
    }

    clickActiveSettingsOption = (id) => {
        this.clearActiveSettingsOption();

        if (id === 1) {
            this.setState({
                openProfileSettings: true,
                detailScreenTitle: 'Profile Settings'
            });
        } else if (id === 2) {
            this.setState({
                openProfileSamples: true,
                detailScreenTitle: 'Profile Samples'
            });
        } else if (id === 3) {
            this.setState({
                openSubscriptionPackages: true,
                detailScreenTitle: 'Subscription Packages'
            });
        } else if (id === 4) {
            this.setState({
                openStoreInventory: true,
                detailScreenTitle: 'Store Inventory'
            });
        } else if (id === 5) {
            this.setState({
                openChatSettings: true,
                detailScreenTitle: 'Chat Settings'
            });
        } else if (id === 6) {
            this.setState({
                openNotificationSettings: true,
                detailScreenTitle: 'Notification Settings'
            });
        } else if (id === 7) {
            this.setState({
                openSecuritySettings: true,
                detailScreenTitle: 'Security Settings'
            });
        } else if (id === 100) {
            this.setState({
                openFanSettings: true,
                detailScreenTitle: 'Fan Settings'
            });
        }

        this.setState({
            anyOptionSelected: true
        });

        document.getElementById("center-bar").style.display = "block";
    }

    clearActiveSettingsOption = () => {
        this.setState({
            anyOptionSelected: false,
            openChatSettings: false,
            openNotificationSettings: false,
            openProfileSamples: false,
            openProfileSettings: false,
            openSecuritySettings: false,
            openStoreInventory: false,
            openStoreInventory: false,
            openSubscriptionPackages: false,
            openFanSettings: false,
            detailScreenTitle: ''
        });//, () => { console.log(this.state); });
    }

    handleCloseDetails = () => {
        this.clearActiveSettingsOption();
        document.getElementById("center-bar").style.display = "none"
    }

    render() {
        return (
            <div className={"main-layout vh-100"}>
                <div className="hide-scrollbar h-100" id="chats-content" style={{ backgroundColor: '#495057' }}>
                    <div className="d-flex flex-column h-100">
                        <div className="hide-scrollbar">
                            {!this.state.loading &&
                                <Header
                                    isAuthenticated={this.state.isAuthenticated}
                                    token={this.state.token}
                                />
                            }
                            <div className="settings-master-component">
                                <div className="settings-master-component-container">
                                    <div className="settings-master-component-wrapper">
                                        {this.state.loading &&
                                            <p className="text-white">Loading...</p>
                                        }
                                        {!this.state.loading &&
                                            <>
                                                <div id="menu-main" className="hide-scrollbar">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h6>Manage Account</h6>
                                                        </div>
                                                        <div className="card-body" style={{ padding: "0" }}>
                                                            <ul className="menu-options">
                                                                {this.state.optionsList.map((option) => (
                                                                    <li className="menu-option friends" key={option.id}>
                                                                        <Link
                                                                            className="menu-option-link"
                                                                            to="#"
                                                                            onClick={() => this.clickActiveSettingsOption(option.id)}
                                                                        >
                                                                            <div className="menu-option-content">
                                                                                <div className="menu-option-info">
                                                                                    <h6 className="menu-option-title text-truncate">{option.val}</h6>
                                                                                    <div className="menu-option-forward">
                                                                                        <span className="material-icons" style={{ color: "#495057" }}>arrow_forward_ios</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </li>
                                                                ))}
                                                                {
                                                                    this.state.isCreator &&
                                                                    <>
                                                                        <li className="menu-option friends">
                                                                            <a className="menu-option-link" href={`/p/${this.state.username}`}>
                                                                                <div className="menu-option-content">
                                                                                    <div className="menu-option-info">
                                                                                        <h6 className="menu-option-title text-truncate">My Profile</h6>
                                                                                        <div className="menu-option-forward">
                                                                                            <span className="material-icons" style={{ color: "#495057" }}>arrow_forward_ios</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                    </>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="center-bar">
                                                    <>
                                                        {this.state.anyOptionSelected &&
                                                            <div id="settings-detail">
                                                                <div className="center-settings-content hide-scrollbar h-100">
                                                                    <div className="settings-messages-container">
                                                                        {this.state.openProfileSettings &&
                                                                            this.state.isCreator &&
                                                                            <ProfileSettings
                                                                                token={this.state.token}
                                                                                creatorId={this.state.creatorId}
                                                                                handleCloseDetails={this.handleCloseDetails}
                                                                            />
                                                                        }
                                                                        {this.state.openProfileSamples &&
                                                                            this.state.isCreator &&
                                                                            <ProfileSamples
                                                                                token={this.state.token}
                                                                                creatorId={this.state.creatorId}
                                                                                handleCloseDetails={this.handleCloseDetails}
                                                                            />
                                                                        }
                                                                        {this.state.openStoreInventory &&
                                                                            this.state.isCreator &&
                                                                            <StoreInventory
                                                                                token={this.state.token}
                                                                                creatorId={this.state.creatorId}
                                                                                handleCloseDetails={this.handleCloseDetails}
                                                                            />
                                                                        }
                                                                        {this.state.openSubscriptionPackages &&
                                                                            this.state.isCreator &&
                                                                            <SubscriptionPackages
                                                                                token={this.state.token}
                                                                                creatorId={this.state.creatorId}
                                                                                handleCloseDetails={this.handleCloseDetails}
                                                                            />
                                                                        }
                                                                        {this.state.openChatSettings &&
                                                                            this.state.isCreator &&
                                                                            <ChatSettings
                                                                                token={this.state.token}
                                                                                creatorId={this.state.creatorId}
                                                                                handleCloseDetails={this.handleCloseDetails}
                                                                            />
                                                                        }
                                                                        {this.state.openSecuritySettings &&
                                                                            this.state.isCreator &&
                                                                            <SecuritySettings
                                                                                token={this.state.token}
                                                                                creatorId={this.state.creatorId}
                                                                                handleCloseDetails={this.handleCloseDetails}
                                                                            />
                                                                        }
                                                                        {this.state.openNotificationSettings &&
                                                                            this.state.isCreator &&
                                                                            <NotificationSettings
                                                                                token={this.state.token}
                                                                                creatorId={this.state.creatorId}
                                                                                handleCloseDetails={this.handleCloseDetails}
                                                                            />
                                                                        }
                                                                        {this.state.openFanSettings &&
                                                                            !this.state.isCreator &&
                                                                            <FanSettings
                                                                                token={this.state.token}
                                                                                userAccountId={this.state.userAccountId}
                                                                                handleCloseDetails={this.handleCloseDetails}
                                                                            />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {!this.state.anyOptionSelected &&
                                                            <SettingsStart

                                                            />
                                                        }
                                                    </>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NavBar activeMenu="account" />
                <div className="backdrop"></div>
            </div >
        );
    }
}
export default Settings;
