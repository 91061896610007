import React, { Component } from "react";
import axios from "axios";

import { ReactComponent as ChatSvg } from "../../assets/media/heroicons/outline/chat.svg";
import { ReactComponent as StarSvg } from "../../assets/media/heroicons/outline/star.svg"

class FindCreators extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchPhrase: '',
            results: [],
            searching: false,
            searched: false
        };
    }

    componentDidMount() {

    }

    search = async () => {
        try {
            this.setState({
                searching: true
            });

            const formData = new FormData();
            formData.append("searchPhrase", this.state.searchPhrase);


            const response = await axios.post('/api/discovery/search', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + this.props.token
                }
            });

            if (response.status != 200) {
                alert("DO SOMETHING. Couldn't perform creator search");
            }

            this.setState({
                results: response.data.results,
                searched: true,
                searchPhrase: ''
            })

            this.scrollToBottom();

        } catch (err) {
            console.log("ERROR SEARCHING", err);
        }
        finally {
            this.setState({
                searching: false
            });
        }
    }

    scrollToBottom = () => {
        if (this.portalEnd) {
            this.portalEnd.scrollIntoView({ behavior: "smooth" });
        }
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <div className="form-group">
                            <label htmlFor="search">Search for creators</label>
                            <input
                                type="text"
                                className="form-control"
                                id="search"
                                placeholder=""
                                value={this.state.searchPhrase}
                                onChange={(e) => {
                                    this.setState({ searchPhrase: e.target.value });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-6 offset-md-3">
                        {
                            !this.state.searching &&
                            <div className="d-flex justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-outline-danger mx-1"
                                    onClick={() => this.setState({ searchPhrase: '', results: [], searched: false })}
                                >
                                    Clear
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary mx-1"
                                    onClick={this.search}
                                >
                                    Search
                                </button>

                            </div>
                        }
                        {
                            this.state.searching &&
                            <p className="text-white">Searching...</p>
                        }
                    </div>
                </div>
                <div className="row mt-1">
                    {
                        !this.state.searching &&
                        this.state.results &&
                        this.state.results.length > 0 &&
                        this.state.searched &&
                        this.state.results.map((result) => (
                            <div className="col-md-4" key={result.creatorId}>
                                <div className="d-flex justify-content-center">
                                    <div className="avatar avatar-xxl mx-5 mt-2">
                                        <img
                                            className="avatar-img"
                                            src={result.creatorAvatar}
                                            alt=""
                                        ></img>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    @{result.creatorUsername}
                                </div>

                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="text-center">
                                        <a className="nav-link text-muted px-0" href={'/p/' + result.creatorUsername}>
                                            <div className="btn btn-primary btn-icon rounded-circle text-light mx-2">
                                                <StarSvg className="injectable hw-20" />
                                            </div>
                                            <span className="small">Profile</span>
                                        </a>
                                    </div>
                                    <div className="text-center">
                                        <a className="nav-link text-muted px-0" href={'/chat?creatorId=' + result.creatorId}>
                                            <div className="btn btn-primary btn-icon rounded-circle text-light mx-2">
                                                <ChatSvg className="injectable hw-24" />
                                            </div>
                                            <span className="small">Chat</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))
                    }


                    <div id="portalEndMarker" style={{ float: "left", clear: "both" }}
                        ref={(el) => { this.portalEnd = el; }}>
                    </div>
                    {
                        !this.state.searching &&
                        this.state.searched &&
                        (!this.state.results || this.state.results.length === 0) &&
                        <div className="col-12">
                            <div className="alert alert-primary" role="alert">
                                No results matching your search
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default FindCreators;