import React, { Component } from "react";

class ChatSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };
    }

    async componentDidMount() {

        this.setState({
            loading: false,
            // TODO: More
        });
    }

    render() {
        return (
            <p className="text-white">CHAT SETTINGS</p>
        );
    }
}

export default ChatSettings;