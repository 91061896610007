import React, { Component } from "react";
import SubscriptionPackageDetails from "../../Modals/Account/Creator/SubscriptionPackageDetails";
import axios from "axios";

class SubscriptionPackages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            showDetails: false,
            subscriptionPackages: [],
            selectedSubscriptionPackage: null
        };
    }

    async componentDidMount() {

        const response = await fetch(`api/account/c/${this.props.creatorId}/subscription_packages`, {
            headers: { 'Authorization': `Bearer ${this.props.token}` }
        });

        if (response.status != 200) {
            alert("DO SOMETHING. Couldn't grab subscription packages");
        }

        const subscriptionPackages = await response.json();

        console.log(subscriptionPackages);

        this.setState({
            loading: false,
            subscriptionPackages: subscriptionPackages
        });
    }

    addSubscriptionPackage = () => {
        this.setState({
            showDetails: true
        });
    }

    showDetails = (id) => {

        let subscriptionPackage = null;

        if (this.state.subscriptionPackages && this.state.subscriptionPackages.length > 0) {
            for (let index = 0; index < this.state.subscriptionPackages.length; index++) {
                if (this.state.subscriptionPackages[index].subscriptionPackageId === id) {
                    subscriptionPackage = this.state.subscriptionPackages[index];
                    break;
                }
            }
        }

        this.setState({
            showDetails: true,
            selectedSubscriptionPackage: subscriptionPackage
        });
    }

    hideSubscriptionPackageDetails = () => {
        this.setState({
            showDetails: false,
            selectedSubscriptionPackage: null
        });
    }

    setSubscriptionPackageDetails = (obj) => {
        if (this.state.subscriptionPackages) {

            const subscriptionPackages = this.state.subscriptionPackages;
            let found = false;
            for (let index = 0; index < subscriptionPackages.length; index++) {
                if (subscriptionPackages[index].subscriptionPackageId === obj.subscriptionPackageId) {
                    subscriptionPackages[index] = obj;
                    this.setState({
                        subscriptionPackages: subscriptionPackages
                    });
                    found = true;
                    break;
                }
            }

            if (!found) {
                console.log("Server response", obj);
                this.setState({
                    subscriptionPackages: [...subscriptionPackages, obj]
                });
            }
        }
    }

    deleteSubscriptionPackage = async (subscriptionPackageId) => {

        if (confirm('Are you sure you want to delete this subscription package? Note: Existing holders will still be honored until canceled')) {
            const response = await axios.delete(`/api/account/c/${this.props.creatorId}/subscription_packages/${subscriptionPackageId}`, {
                headers: {
                    "Authorization": "Bearer " + this.props.token
                }
            });

            if (response.status != 200) {
                alert("DO SOMETHING. Couldn't delete subscription package");
            } else if (this.state.subscriptionPackages) {
                const subscriptionPackages = this.state.subscriptionPackages;
                let index = -1;
                for (let i = 0; i < subscriptionPackages.length; i++) {
                    if (subscriptionPackages[i].subscriptionPackageId === subscriptionPackageId) {
                        index = i;
                        break;
                    }
                }

                if (index >= 0) {
                    subscriptionPackages.splice(index, 1);
                    this.setState({
                        subscriptionPackages: subscriptionPackages
                    });
                }
            }
        }
    }

    render() {
        return (
            <>
                {
                    this.state.loading &&
                    <p className="text-white">Loading...</p>
                }
                {!this.state.loading &&
                    <div className="card mb-3">
                        <div className="card-header">
                            <div className="back-to-contact" onClick={this.props.handleCloseDetails} >
                                <span className="material-icons" style={{ color: "#495057" }}>arrow_back_ios</span>
                                <span className="text-white ml-2">Subscription Packages</span>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="text-left mb-1">
                                <span className="material-icons text-primary" style={{ cursor: "pointer" }} onClick={this.addSubscriptionPackage}>add_circle_outline</span>
                            </div>
                            {
                                this.state.subscriptionPackages.length > 0 &&
                                this.state.subscriptionPackages.map((subscriptionPackage) => (

                                    <div className="row text-white mb-1" key={subscriptionPackage.subscriptionPackageId}>
                                        <div className="col-md-6">
                                            {subscriptionPackage.numDays} day package - {subscriptionPackage.displayName} ({subscriptionPackage.costSatoshis} sats)
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <button
                                                className="btn btn-danger"
                                                type="button"
                                                onClick={() => this.deleteSubscriptionPackage(subscriptionPackage.subscriptionPackageId)}>
                                                <span className="text-white">Delete</span>
                                            </button>
                                            <button
                                                className="btn btn-primary ml-2"
                                                type="button"
                                                onClick={() => this.showDetails(subscriptionPackage.subscriptionPackageId)}>
                                                <span className="text-white">Edit</span>
                                            </button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }

                {
                    this.state.showDetails &&
                    <SubscriptionPackageDetails
                        hideSubscriptionPackageDetails={this.hideSubscriptionPackageDetails}
                        setSubscriptionPackageDetails={this.setSubscriptionPackageDetails}
                        subscriptionPackage={this.state.selectedSubscriptionPackage}
                        token={this.props.token}
                        creatorId={this.props.creatorId}
                    />
                }
            </>
        );
    }
}

export default SubscriptionPackages;