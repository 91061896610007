import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { QRCodeCanvas } from "qrcode.react";
import axios from "axios";

class SubscribeCreator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            generatingInvoice: false,
            invoiceGenerated: false,
            bolt11: '',
            message: '',
            alreadySubscribed: false
        };
    }

    setModalShow = () => {
        this.props.hideSubscribeCreator();
    }

    numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    generateInvoice = async (subscriptionPackage) => {
        try {
            this.setState({
                generatingInvoice: true
            });

            const response = await axios.post(`/api/profile/subscribe/${subscriptionPackage.subscriptionPackageId}/c/${this.props.creatorId}`, null, {
                headers: {
                    "Authorization": "Bearer " + this.props.token
                }
            });

            if (response.status != 200) {
                alert("DO SOMETHING. Couldn't create invoice");
            }

            this.setState({
                invoiceGenerated: true,
                alreadySubscribed: response.data.alreadySubscribed,
                bolt11: response.data.bolT11,
                message: `Please pay ${this.numberWithCommas(subscriptionPackage.costSatoshis)} sats to subscribe to ${this.props.creatorUsername} for ${subscriptionPackage.numDays} day(s)`
            });
        } catch (err) {
            alert(err);
        }
        finally {
            this.setState({
                generatingInvoice: false
            });
        }
    }

    refreshPage = () => {
        location.reload();
    }

    render() {
        return (

            <Modal
                show={true}
                scrollable={true}
                onHide={() => this.setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Subscribe to {this.props.creatorUsername}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0 hide-scrollbar">
                    <div className="row">
                        <div className="col-10 offset-1 mt-2">
                            {
                                !this.state.invoiceGenerated &&
                                !this.state.alreadySubscribed &&

                                <table className="table table-dark text-white">
                                    <thead>
                                        <tr style={{ border: 'none' }}>
                                            <th># days</th>
                                            <th>Cost (satoshis)</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.props.subscriptionPackages &&
                                            this.props.subscriptionPackages.map((subscriptionPackage) => (
                                                <tr key={subscriptionPackage.subscriptionPackageId}>
                                                    <td>{subscriptionPackage.numDays}</td>
                                                    <td>{this.numberWithCommas(subscriptionPackage.costSatoshis)}</td>
                                                    <td>
                                                        {
                                                            !this.state.generatingInvoice &&
                                                            <button className="btn btn-primary"
                                                                onClick={() => this.generateInvoice(subscriptionPackage)}
                                                            >Pay invoice</button>
                                                        }
                                                        {
                                                            this.state.generatingInvoice &&
                                                            <span>Generating Invoice...</span>
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            }
                            {
                                this.state.invoiceGenerated &&
                                !this.state.alreadySubscribed &&
                                <>
                                    <div className="d-flex justify-content-center mt-2 mb-2">
                                        <QRCodeCanvas value={this.state.bolt11.toUpperCase()} size={256} level={"H"} includeMargin={true} />
                                    </div>
                                    <div className="d-flex justify-content-center mb-2">
                                        <button className="btn btn-sm btn-secondary" role="button" onClick={() => { navigator.clipboard.writeText(this.state.bolt11.toUpperCase()) }}>Copy</button>
                                    </div>
                                    <div className="d-flex justify-content-center mb-2">
                                        <p className="text-white small">
                                            <small>{this.state.message}. Once you've paid the invoice, click &quot;Done&quot; or refresh this page</small>
                                        </p>
                                    </div>
                                </>
                            }
                            {
                                this.state.alreadySubscribed &&
                                <p className="text-white">
                                    Already subscribed. Send a tip instead!
                                </p>
                            }
                            <div className="d-flex justify-content-center mt-2 mb-2">
                                <button
                                    type="button"
                                    className="btn btn-primary mx-1"
                                    onClick={this.refreshPage}
                                >
                                    Done
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        );
    }
}

export default SubscribeCreator;