import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import { QRCodeCanvas } from "qrcode.react";
import axios from "axios";

class TipCreator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            amount: props.suggestedTip,
            generatingInvoice: false,
            invoiceGenerated: false,
            bolt11: '',
            message: '',
            tipMessage: '',
            paid: false
        };

        //console.log(props);
    }

    setModalShow = () => {
        this.props.hideTipCreator();
    }

    updateTipAmount = (e) => {
        this.setState({
            amount: e.target.value
        })
    }

    updateTipMessage = (e) => {
        this.setState({
            tipMessage: e.target.value
        })
    }

    numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    generateInvoice = async () => {
        if (confirm(`Tip ${this.numberWithCommas(this.state.amount)} sats to ${this.props.creatorUsername}?`)) {
            try {
                this.setState({
                    generatingInvoice: true
                });

                const formData = new FormData();

                formData.append("satoshis", this.state.amount);
                formData.append("message", this.state.tipMessage);

                const response = await axios.post(`/api/profile/tip/c/${this.props.creatorId}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": "Bearer " + this.props.token
                    }
                });

                if (response.status != 200) {
                    alert("DO SOMETHING. Couldn't create invoice");
                }

                this.setState({
                    invoiceGenerated: true,
                    bolt11: response.data.bolT11,
                    paid: response.data.paidWithAlby,
                    message: `Please pay ${this.numberWithCommas(this.state.amount)} sats to ${this.props.creatorUsername} as a tip`
                });
            } catch (err) {
                alert(err);
            }
            finally {
                this.setState({
                    generatingInvoice: false
                });
            }
        }
    }

    render() {
        return (

            <Modal
                show={true}
                scrollable={true}
                onHide={() => this.setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>Tip {this.props.creatorUsername}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0 hide-scrollbar">
                    {
                        !this.state.invoiceGenerated &&

                        <div className="row">
                            <div className="col-10 offset-1 mt-2">
                                <div className="input-group d-flex justify-content-center mb-2">
                                    <input
                                        type="text"
                                        className="form-control form-control-md"
                                        value={this.state.amount}
                                        onChange={this.updateTipAmount}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">sats</span>
                                    </div>
                                </div>
                                <div className="input-group d-flex justify-content-center mb-2">
                                    <input
                                        type="text"
                                        className="form-control form-control-md"
                                        value={this.state.tipMessage}
                                        onChange={this.updateTipMessage}
                                        placeholder="Optional message"
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {
                        !this.state.invoiceGenerated &&
                        !this.state.generatingInvoice &&
                        !this.state.paid &&
                        <div className="d-flex justify-content-center mt-2 mb-2">
                            <button
                                type="button"
                                className="btn btn-outline-danger mx-1"
                                onClick={() => this.setModalShow(false)}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary mx-1"
                                onClick={this.generateInvoice}
                            >
                                Pay Invoice
                            </button>
                        </div>
                    }
                    {
                        this.state.generatingInvoice &&
                        <div className="d-flex justify-content-center mt-2 mb-2">
                            <p className="text-white">
                                Generating invoice and attempting payment...
                            </p>
                        </div>
                    }
                    {
                        this.state.invoiceGenerated &&
                        !this.state.paid &&
                        <>
                            <div className="d-flex justify-content-center mt-2 mb-2">
                                <QRCodeCanvas value={this.state.bolt11.toUpperCase()} size={256} level={"H"} includeMargin={true} />
                            </div>
                            <div className="d-flex justify-content-center mb-2">
                                <button className="btn btn-sm btn-secondary" role="button" onClick={() => { navigator.clipboard.writeText(this.state.bolt11.toUpperCase()) }}>Copy</button>
                            </div>
                            <div className="d-flex justify-content-center mb-2">
                                <p className="text-white small">
                                    <small>{this.state.message}</small>
                                </p>
                            </div>
                            <div className="d-flex justify-content-center mt-2 mb-2">
                                <button
                                    type="button"
                                    className="btn btn-primary mx-1"
                                    onClick={() => this.setModalShow(false)}
                                >
                                    Done
                                </button>
                            </div>
                        </>
                    }
                    {
                        this.state.paid &&
                        <>
                            <div className="d-flex justify-content-center mb-2">
                                <p className="text-white small">
                                    You successfully tipped {this.props.creatorUsername} {this.numberWithCommas(this.state.amount)} sats. Thank you!
                                </p>
                            </div>
                            <div className="d-flex justify-content-center mt-2 mb-2">
                                <button
                                    type="button"
                                    className="btn btn-primary mx-1"
                                    onClick={() => this.setModalShow(false)}
                                >
                                    Done
                                </button>
                            </div>
                        </>
                    }
                </Modal.Body>
            </Modal >
        );
    }
}

export default TipCreator;