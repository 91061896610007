import React, { Component } from "react";
import { Link } from "react-router-dom";
import Notifications from "../Modals/Notifications/Notifications";
import { ReactComponent as NotificationsSvg } from "../../assets/media/icons/notifications.svg";
import { ReactComponent as VerticalOptionDots } from "../../assets/media/icons/verticaloptiondots.svg";
import { Dropdown } from "react-bootstrap";
import authService from '../api-authorization/AuthorizeService';

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showNotification: false,
            showOtherOption1: false,
            showOtherOption2: false,
            showOtherOption3: false,
            loadingNotifications: true,
            notifications: []
        };
    }

    toggleNotification = () => {
        this.setState({
            showNotification: !this.state.showNotification,
        });
    };

    async componentDidMount() {
        try {

            const response = await fetch('api/account/notifications', {
                headers: { 'Authorization': `Bearer ${this.props.token}` }
            });

            if (response.status != 200) {
                console.log("Could not retrieve notifications");
            }

            const notifications = await response.json();
            //console.log(notifications);

            this.setState({
                notifications: notifications
            });

        } catch (err) {

        } finally {
            this.setState({
                loadingNotifications: false
            });
        }
    }

    render() {
        return (
            <>
                <div className="sidebar-header sticky-top p-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <img src="/images/logo2.png" height="32" />
                        {
                            this.props.isAuthenticated &&
                            <ul className="nav flex-nowrap">
                                {
                                    !this.state.loadingNotifications &&
                                    <li className="nav-item list-inline-item mr-1">
                                        <Link
                                            className="nav-link text-muted px-1"
                                            title="Notifications"
                                            role="button"
                                            data-toggle="modal"
                                            data-target="#notificationModal"
                                            onClick={this.toggleNotification}
                                            to="#"
                                        >
                                            <NotificationsSvg /> {
                                                this.state.notifications &&
                                                this.state.notifications.length > 0 &&
                                                <span className="badge badge-primary">{this.state.notifications.length}</span>
                                            }
                                        </Link>
                                    </li>
                                }
                                <li className="nav-item list-inline-item mr-0">
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            className="text-muted hw-20 mt-2"
                                            as={VerticalOptionDots}
                                        ></Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                            <Link
                                                className="dropdown-item"
                                                to="/portal"
                                            >
                                                Home
                                            </Link>
                                            <Link
                                                className="dropdown-item"
                                                to="/account"
                                            >
                                                Manage Account
                                            </Link>
                                            <a
                                                className="dropdown-item"
                                                href="/Identity/Account/Logout"
                                            >
                                                Logout
                                            </a>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                            </ul>
                        }
                    </div>
                </div>
                {
                    this.state.showNotification &&
                    <Notifications
                        hideNotification={this.toggleNotification}
                        notifications={this.state.notifications}
                        token={this.props.token}
                    />
                }
            </>
        );
    }
}

export default Header;